const truncate = (
  address: string,
  firstCharacterAmount: number = 7,
  lastCharacterAmount: number = 5
) => {
  const firstString = address.substring(0, firstCharacterAmount)
  const lastString = address.slice(0 - lastCharacterAmount)
  return address ? firstString + "..." + lastString : ""
}

export { truncate }
