export interface TimeFormat {
  displayDay: number
  displayHour: number
  displayMinutes: number
}
const compute = {
  roundNumber(num: number, precision: number): string {
    return num.toFixed(precision).replace(/(\.0+|0+)$/, "")
  },

  addSeparator(num: number): string {
    return num.toFixed(0).replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
  },

  getRemainingTIme(endTime: number): TimeFormat {
    if (endTime < Date.now()) return { displayDay: 0, displayHour: 0, displayMinutes: 0 }

    let remainingTime = 0
    const remainingSeconds = endTime / 1000 - Date.now() / 1000
    const remainingMinutes = remainingSeconds / 60 >= 1 ? remainingSeconds / 60 : 1
    const remainingHours = remainingMinutes / 60

    if (remainingHours >= 24) remainingTime = Math.floor(remainingHours)
    else remainingTime = Math.floor(remainingMinutes)

    const displayDay = remainingHours >= 24 ? Math.floor(remainingHours / 24) : 0
    const displayHour = Math.floor(remainingHours)
    const displayMinutes =
      remainingHours >= 24
        ? Math.floor(remainingMinutes - Math.floor(remainingHours) * 60)
        : remainingTime % 60

    return { displayDay, displayHour, displayMinutes }
  },

  getClaimWaitingTime(milliseconds: number): TimeFormat {
    if (milliseconds <= 0) return { displayDay: 0, displayHour: 0, displayMinutes: 0 }

    let leftTime = 0
    const leftSeconds = milliseconds / 1000
    const leftMinutes = leftSeconds / 60 >= 1 ? leftSeconds / 60 : 1
    const leftHours = leftMinutes / 60

    if (leftHours >= 24) leftTime = Math.floor(leftHours)
    else leftTime = Math.floor(leftMinutes)

    const displayDay = leftHours >= 24 ? Math.floor(leftHours / 24) : 0
    const displayHour = Math.floor(leftHours >= 24 ? leftTime % 24 : leftTime / 60)
    const displayMinutes =
      leftHours >= 24 ? Math.floor(leftMinutes - Math.floor(leftHours) * 60) : leftTime % 60

    return { displayDay, displayHour, displayMinutes }
  },

  roundNumberShort(num: number): string {
    const digit = Math.floor(Math.log10(num))
    const precision = digit > 2 ? 2 : digit <= 2 && digit >= 0 ? 4 - digit : 4
    const regExp = /^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/

    return num >= 1000
      ? num
          .toFixed(precision)
          .replace(regExp, "$1$2$3")
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")
      : num.toFixed(precision).replace(regExp, "$1$2$3")
  },

  roundApr(num: number): string {
    return num.toFixed(2).replace(/(\.0+|0+)$/, "")
  },

  formatNumber(num: number): string {
    const [digit, decimal] = num.toString().split(".")
    const rest = decimal ? `.${decimal}` : decimal === "" ? "." : ""
    return `${digit.replace(/\B(?=(?:\d{3})+(?!\d))/g, ",")}${rest}`
  }
}

export default compute
