import React, { useState } from "react"
import { useSelector } from "react-redux"
import { selectPOS } from "../../../../store/store"
import compute from "../../../../utils/compute"
import StyledCheckbox from "../../../Checkbox/Checkbox"
import { useTranslation } from "react-i18next"

interface IRequestingProps extends IRequestingOwnProps {}

interface IRequestingOwnProps {
  ttVeTTRatio: number
  stakeAmount: number
  handleCloseRequesting: () => void
  handleStake: () => void
}

const Requesting: React.FC<IRequestingProps> = (props: IRequestingProps) => {
  const { t } = useTranslation()
  const posProject = useSelector(selectPOS).projectStatus
  const { ttVeTTRatio, stakeAmount, handleCloseRequesting, handleStake } = props
  const [checkBox1, setCheckBox1] = useState(false)
  const [checkBox2, setCheckBox2] = useState(false)
  const canProceed = checkBox1 && checkBox2

  return (
    <div>
      <div className="mt-[16px] text-[24px] leading-[32px] dark:text-white text-black font-mont font-semibold text-center">
        {t("staking.stake.stake")}
      </div>
      <div className="flex flex-col space-y-[8px] px-[18px] py-[16px] mt-[16px] bg-variant-50 dark:bg-neutrals-800 rounded-[10px]">
        <div className="flex flex-row justify-between">
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {t("staking.stake.amount")}
          </div>
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {compute.roundNumberShort(stakeAmount)} TT
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {t("staking.stake.youWillGet")}
          </div>
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {compute.roundNumberShort(stakeAmount * ttVeTTRatio)} veTT
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {t("staking.stake.apy")}
          </div>
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {`${compute.roundApr(posProject.apy * 100)}%`}
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-[48px] items-start">
        <StyledCheckbox
          checked={checkBox1}
          onChange={() => setCheckBox1(!checkBox1)}
          sx={{ padding: 0, paddingRight: "8px" }}
        />
        <div className="text-[14px] leading-[20px] flex-1">{t("staking.stake.checkbox1")}</div>
      </div>
      <div className="flex flex-row mt-[16px] items-start">
        <StyledCheckbox
          checked={checkBox2}
          onChange={() => setCheckBox2(!checkBox2)}
          sx={{ padding: 0, paddingRight: "8px" }}
        />
        <div className="text-[14px] leading-[20px] flex-1">{t("staking.stake.checkbox2")}</div>
      </div>
      <div className="absolute w-full left-0 bottom-[16px] px-[16px] flex flex-row space-x-[16px]">
        <button onClick={() => handleCloseRequesting()} className="btn btn-link h-[48px] flex-1">
          {t("staking.stake.cancel")}
        </button>
        <button
          onClick={() => handleStake()}
          disabled={!canProceed}
          className="btn btn-secondary h-[48px] flex-1"
        >
          {t("staking.stake.stake")}
        </button>
      </div>
    </div>
  )
}

export default Requesting
