const env = {
  rpcUrl: parseEnv("RPC_URL"),
  chainId: parseEnv("CHAIN_ID"),
  posBlockHeight: parseEnv("POS_BLOCK_HEIGHT"),
  stakingContractAddress: parseEnv("STAKING_CONTRACT_ADDRESS"),
  avgMillisecondPerBlock: parseEnv("AVG_MILLISECOND_PER_BLOCK"),
  stopBlockSessionOffset: parseEnv("STOP_BLOCK_SESSION_OFFSET"),
  // quotationContractAddress: parseEnv(
  //   "QUOTATION_CONTRACT_ADDRESS",
  //   "0x6A7A5Bb0922abe4a17fB0a169ce6CA194493E23b"
  // ),
  oracleContractAddress: parseEnv("ORACLE_CONTRACT_ADDRESS"),
  explorerUrl: parseEnv("EXPLORER_URL"),
  totalSupplyBeforeHardfork: parseEnv("TOTAL_SUPPLY_BEFORE_HARDFORK", "1000000"),
  gqlEndpoint: parseEnv("GQL_ENDPOINT"),
  blockscoutApiUrl: parseEnv("BLOCKSCOUT_API_URL"),
  circulatingSupplyUrl: parseEnv("CIRCULATING_SUPPLY_URL", "circulatingSupplyUrlNotSet")
}
export default env

export function stakingHelperCtorArg(config: {
  rpcUrl: string
  chainId: string
  stakingContractAddress: string
  stopBlockSessionOffset: string
  avgMillisecondPerBlock: string
  gqlEndpoint: string
  blockscoutApiUrl: string
}) {
  return {
    rpc: config.rpcUrl,
    network: +config.chainId,
    stakingAddress: config.stakingContractAddress,
    stopBlockSessionOffset: +config.stopBlockSessionOffset,
    blockTime: +config.avgMillisecondPerBlock / 1000.0,
    pos2SubgraphUrl: config.gqlEndpoint,
    blockscoutApiUrl: config.blockscoutApiUrl
  }
}

export function parseEnv(name: string, defaultVal?: string): string {
  const env = window._env_[name] || defaultVal
  if (!env) {
    throw new Error(`Missing environment variable for ${name}`)
  }
  return env
}

// function parseEnvInt(name: string): number {
//   return parseInt(window._env_[name], 10)
// }

export function calcEpochInMs(config: {
  stopBlockSessionOffset: string
  avgMillisecondPerBlock: string
}) {
  // Number of blocks till stopBlock plus two consecutive normal blocks
  const sessionBlocks = +config.stopBlockSessionOffset + 2
  const epochInMs = sessionBlocks * +config.avgMillisecondPerBlock
  return epochInMs
}
