import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { utils } from "ethers"
import { useTranslation } from "react-i18next"

import StyledTooltip from "../Tooltip/Tooltip"

import { IPosState } from "../../store/posSlice"

import compute from "../../utils/compute"
import useChainService from "../../hooks/useChainService"
import useExchangeRatio from "../../hooks/useExchangeRatio"

import infoIcon from "../../assets/Info.svg"
import plusIcon from "../../assets/plus.svg"

const StakingFormUserStats = (props: {
  pos: IPosState
  unstakedSuccess: boolean
  handleClaim: () => void
  setGetTTModal: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const { ttVeTTRatio } = useExchangeRatio()
  const { pos, unstakedSuccess, handleClaim, setGetTTModal } = props
  const { ttPriceUsd } = pos.projectStatus
  const { balance, walletAddress, stakedVeTT, claimAmount, showAddress, earnedReward } =
    pos.userStatus
  const [canClaim, setCanClaim] = useState(false)
  const [claimWaitingTime, setClaimWaitingTime] = useState({
    displayDay: 0,
    displayHour: 0,
    displayMinutes: 0
  })
  const [epochsToWait, setEpochsToWait] = useState(0)
  const [showClaimTip, setShowClaimTip] = useState(false)
  const [showRewardTip, setShowRewardTip] = useState(false)
  const { checkClaimability, getRemainingEpochToClaim, getUserWithdrawWaitingTime } =
    useChainService()
  const claimTip = t("staking.balance.unstakedTips")
  const rewardTip = t("staking.balance.rewardsTips")

  useEffect(() => {
    const isCanClaim = async () => {
      if (await checkClaimability(walletAddress)) {
        setCanClaim(true)
        return
      }
      setClaimWaitingTime(
        compute.getClaimWaitingTime(await getUserWithdrawWaitingTime(walletAddress))
      )
      setEpochsToWait(await getRemainingEpochToClaim(walletAddress))
    }
    if (walletAddress !== "") isCanClaim()
  }, [walletAddress, unstakedSuccess])

  const ttBalance = compute.roundNumberShort(balance)
  const userStakedVeTT = compute.roundNumberShort(+utils.formatEther(stakedVeTT))
  const ttAmount =
    stakedVeTT === "0" || ttVeTTRatio === 0
      ? "0"
      : compute.roundNumberShort(+utils.formatEther(stakedVeTT) / Number(ttVeTTRatio))
  const ttAmountUsd =
    stakedVeTT === "0" || ttVeTTRatio === 0
      ? "0"
      : compute.roundNumberShort(
          (+utils.formatEther(stakedVeTT) / Number(ttVeTTRatio)) * ttPriceUsd
        )
  const earnedRewardUsd =
    earnedReward === 0 ? "0" : compute.roundNumberShort(earnedReward * ttPriceUsd)

  return (
    <div className="flex flex-col bg-white dark:bg-neutrals-900 rounded-[14px] p-[16px] md:p-[24px] mb-[24px] space-y-[16px]">
      <div className="flex flex-col space-y-[4px]">
        <div className="text-secondary text-[14px] leading-[20px] font-medium">
          {t("staking.balance.yourBalance")}
        </div>
        <div className="flex flex-row items-center">
          <div className="text-[16px] leading-[24px] font-bold">{`${
            showAddress ? ttBalance : "0"
          } TT`}</div>
          <img
            src={plusIcon}
            className="w-[20px] h-[20px] ml-[8px] cursor-pointer"
            alt="Buy TT"
            onClick={() => {
              setGetTTModal(true)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row space-y-[16px] md:space-y-0">
        <div className="flex flex-1 flex-col">
          <div className="text-secondary text-[14px] leading-[20px] font-medium">
            {t("staking.balance.staked")}
          </div>
          <div className="text-[16px] leading-[24px] font-bold">{`${userStakedVeTT} veTT`}</div>
          <div className="text-[12px] leading-[20px] text-secondary">{`≈ ${ttAmount} TT (≈ ${ttAmountUsd} USD)`}</div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="flex flex-row">
            <div className="text-secondary text-[14px] leading-[20px] font-medium">
              {t("staking.balance.rewards")}
            </div>
            <StyledTooltip
              placement="bottom-start"
              title={rewardTip}
              open={showRewardTip}
              leaveTouchDelay={10000}
              onOpen={() => setShowRewardTip(true)}
              onClose={() => setShowRewardTip(false)}
            >
              <img
                className="w-[20px] h-[20px] ml-[4px]"
                onClick={() => setShowRewardTip(!showRewardTip)}
                src={infoIcon}
                alt={"info"}
              />
            </StyledTooltip>
          </div>
          <div className="text-[16px] leading-[24px] font-bold">{`+ ${compute.roundNumberShort(
            earnedReward
          )} TT`}</div>
          <div className="text-[12px] leading-[20px] text-secondary">{`≈ ${earnedRewardUsd} USD`}</div>
        </div>
      </div>
      {claimAmount > 0 && (
        <div
          className={`
          w-full flex flex-row justify-between px-[18px] p-[12px] bg-variant-50 rounded-[10px] 
          border-solid border-[1px] border-${epochsToWait > 0 ? "br-light" : "primary-700"}
         dark:bg-neutrals-800 dark:border-${epochsToWait > 0 ? "neutrals-700" : "secondary-500"}
        `}
        >
          <div>
            <div className="flex flex-row">
              <span className=" text-secondary dark:text-neutrals-500 text-[14px] leading-[20px] font-medium mb-[8px]">
                {t("staking.balance.unstaked")}
              </span>
              <StyledTooltip
                title={claimTip}
                open={showClaimTip}
                leaveTouchDelay={10000}
                onOpen={() => setShowClaimTip(true)}
                onClose={() => setShowClaimTip(false)}
                placement="bottom-start"
              >
                <img
                  className="w-[20px] h-[20px] ml-[4px]"
                  onClick={() => setShowClaimTip(!showClaimTip)}
                  src={infoIcon}
                  alt={"info"}
                />
              </StyledTooltip>
            </div>
            <div className="text-[16px] leading-[24px] font-bold">
              {`${compute.roundNumberShort(claimAmount)} TT`}
            </div>
            <div className="text-secondary text-[12px] leading-[20px]">
              {epochsToWait > 0
                ? t("staking.balance.epochs", {
                    epochsCount: epochsToWait,
                    daysCount: `${claimWaitingTime.displayDay}d ${claimWaitingTime.displayHour}h ${claimWaitingTime.displayMinutes}m`
                  })
                : t("staking.balance.claimNow")}
            </div>
          </div>

          <div className="flex items-end">
            {epochsToWait > 0 || !canClaim ? (
              <div className="flex flex-row items-center py-[2px] px-[8px] bg-primary-700 dark:bg-primary-600 rounded-[24px] space-x-[4px]">
                <div className="w-[6px] h-[6px] rounded-[6px] bg-white"></div>
                <span className="text-white text-[12px] leading-[20px] font-medium">
                  {t("staking.balance.processing")}
                </span>
              </div>
            ) : (
              <button
                onClick={canClaim ? () => handleClaim() : undefined}
                className="btn btn-primary w-[68px] h-[32px] text-[12px] leading-[20px] rounded-[6px]"
              >
                {t("staking.balance.claim")}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default StakingFormUserStats
