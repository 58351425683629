import React from "react"
// import { useBreakpoints } from '../../hooks/useBreakpoints'
import creditCardImg from "../../assets/landing/howto/creditCard.png"
import creditCardDarkImg from "../../assets/landing/howto/creditCardDark.png"
import exchangeImg from "../../assets/landing/howto/exchange.png"
import exchangeDarkImg from "../../assets/landing/howto/exchangeDark.png"
import multichainImg from "../../assets/landing/howto/multichain.png"
import sushiSwapImg from "../../assets/landing/howto/sushiSwap.png"
import getReturnImg from "../../assets/landing/howto/getReturn.png"
import chooseAmountDarkImg from "../../assets/landing/howto/chooseAmountDark.png"
import chooseAmountImg from "../../assets/landing/howto/chooseAmount.png"
import getRewardImg from "../../assets/landing/howto/getReward.png"
import getRewardDarkImg from "../../assets/landing/howto/getRewardDark.png"
import automaticallyImg from "../../assets/landing/howto/automatically.png"
import automaticallyDarkImg from "../../assets/landing/howto/automaticallyDark.png"

import huobiImg from "../../assets/landing/howto/huobi.svg"
import kucoinImg from "../../assets/landing/howto/kucoin.svg"
import gateioImg from "../../assets/landing/howto/gateio.svg"
import upbitImg from "../../assets/landing/howto/upbit.svg"
import huobiDarkImg from "../../assets/landing/howto/huobiDark.svg"
import kucoinDarkImg from "../../assets/landing/howto/kucoinDark.svg"
import gateioDarkImg from "../../assets/landing/howto/gateioDark.svg"
import upbitDarkImg from "../../assets/landing/howto/upbitDark.svg"

import linkImg from "../../assets/link.svg"
import linkDarkImg from "../../assets/linkDark.svg"
import { useTranslation } from "react-i18next"
import config from "../../config/index"
interface IHowtoStakeProps extends IHowtoStakeOwnProps {}

interface IHowtoStakeOwnProps {
  isDarkMode: boolean
}

const HowtoStake: React.FC<IHowtoStakeProps> = (props) => {
  const { t } = useTranslation()
  const { isDarkMode } = props
  // const { isMd } = useBreakpoints()

  const handleClick = (type: string) => {
    switch (type) {
      case "card":
        window.open(config.getTTcard, "_blank")
        break
      case "exchange":
        window.open(config.getTTexchange, "_blank")
        break
      case "bridge":
        window.open(config.getTTbridge, "_blank")
        break
      case "dex":
        window.open(config.getTTdex, "_blank")
        break
    }
  }
  return (
    <div
      className="`
      flex flex-col space-y-[16px] w-full justify-center items-center
      lg:flex-row lg:space-x-[16px] lg:space-y-0
    `"
    >
      <div className="bg-white dark:bg-neutrals-900 p-[24px] rounded-[14px] w-full md:max-w-[476px] lg:max-w-[384px]">
        <h3 className="text-secondary-500 text-[16px] leading-[24px] font-bold italic">
          {t("landing.howto.step1")}
        </h3>
        <span className="text-[20px] leading-[28px] font-medium mt-[2px]">
          {t("landing.howto.getTT")}
        </span>
        <p className="text-[16px] leading-[24px] mt-[16px]">{t("landing.howto.desc")}</p>

        <div className="flex flex-col space-y-[16px] mt-[16px]">
          <div
            className="flex flex-row justify-between py-[13px] px-[12px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px] cursor-pointer"
            onClick={() => {
              handleClick("card")
            }}
          >
            <div className="flex flex-row">
              <img
                className="w-[24px] h-[24px]"
                src={isDarkMode ? creditCardDarkImg : creditCardImg}
                alt="Credit Card"
              />
              <span className="self-center text-[14px] leading-[20px] text-black dark:text-white ml-[8px]">
                {t("landing.howto.creditCard")}
              </span>
            </div>
            <img
              className="w-[20px] h-[20px]"
              src={isDarkMode ? linkDarkImg : linkImg}
              alt="link"
            />
          </div>

          <div
            className="flex flex-row justify-between py-[13px] px-[12px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px] cursor-pointer"
            onClick={() => {
              handleClick("exchange")
            }}
          >
            <div className="flex flex-row">
              <img
                className="w-[24px] h-[24px]"
                src={isDarkMode ? exchangeDarkImg : exchangeImg}
                alt="Exchange"
              />
              <span className="self-center text-[14px] leading-[20px] text-black dark:text-white ml-[8px]">
                {t("landing.howto.exchange")}
              </span>
            </div>
            <div className="flex flex-row space-x-[8px]">
              <img src={isDarkMode ? kucoinDarkImg : kucoinImg} alt="Kucoin" />
              <img src={isDarkMode ? gateioDarkImg : gateioImg} alt="Gateio" />
              <img src={isDarkMode ? huobiDarkImg : huobiImg} alt="Huobi" />
              <img src={isDarkMode ? upbitDarkImg : upbitImg} alt="Upbit" />
              <img
                className="w-[20px] h-[20px]"
                src={isDarkMode ? linkDarkImg : linkImg}
                alt="link"
              />
            </div>
          </div>

          {/* <div
            className="flex flex-row justify-between py-[13px] px-[12px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px] cursor-pointer"
            onClick={() => {
              handleClick("bridge")
            }}
          >
            <div className="flex flex-row space-x-[8px]">
              <img className="w-[24px] h-[24px]" src={multichainImg} alt="Multichain" />
              <span className="self-center text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.multichain")}
              </span>
              <span className="self-center bg-primary-700 dark:bg-neutrals-700 text-white dark:text-white text-[12px] font-medium leading-[20px] rounded-[10px] px-[8px]">
                {t("landing.howto.bridge")}
              </span>
            </div>
            <img
              className="w-[20px] h-[20px]"
              src={isDarkMode ? linkDarkImg : linkImg}
              alt="link"
            />
          </div> */}

          <div
            className="flex flex-row justify-between py-[13px] px-[12px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px] cursor-pointer"
            onClick={() => {
              handleClick("dex")
            }}
          >
            <div className="flex flex-row space-x-[8px]">
              <img className="w-[24px] h-[24px]" src={sushiSwapImg} alt="SushiSwap" />
              <span className="self-center text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.sushiSwap")}
              </span>
              <span className="self-center bg-primary-700 dark:bg-neutrals-700 text-white dark:text-white text-[12px] font-medium leading-[20px] rounded-[10px] px-[8px]">
                {t("landing.howto.dex")}
              </span>
            </div>
            <img
              className="w-[20px] h-[20px]"
              src={isDarkMode ? linkDarkImg : linkImg}
              alt="link"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-[16px] lg:space-y-[24px] w-full md:max-w-[476px] lg:max-w-[384px]">
        <div className="bg-white dark:bg-neutrals-900 p-[24px] rounded-[14px]">
          <h3 className="text-secondary-500 text-[16px] leading-[24px] font-bold italic">
            {t("landing.howto.step2")}
          </h3>
          <span className="text-[20px] leading-[28px] font-medium mt-[2px]">
            {t("landing.howto.stakeTT")}
          </span>
          <div className="flex flex-col lg:flex-row space-y-[16px] lg:space-y-0 lg:space-x-[16px] mt-[16px]">
            <div
              className="`
              flex flex-row flex-1 space-x-[8px] p-[14px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px]
              lg:flex-col lg:space-y-[8px] lg:space-x-0 lg:p-[12px]
            `"
            >
              <img
                className="w-[24px] h-[24px]"
                src={isDarkMode ? chooseAmountDarkImg : chooseAmountImg}
                alt="Choose an amount "
              />
              <span className="self-center lg:self-start text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.amount")}
              </span>
            </div>
            <div
              className="`
              flex flex-row flex-1 space-x-[8px] p-[14px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px]
              lg:flex-col lg:space-y-[8px] lg:space-x-0 lg:p-[12px]
            `"
            >
              <img className="w-[24px] h-[24px]" src={getReturnImg} alt="Get veTT in return" />
              <span className="self-center lg:self-start text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.return")}
              </span>
            </div>
          </div>
        </div>
        <div className="bg-white dark:bg-neutrals-900 p-[24px] rounded-[14px]">
          <h3 className="text-secondary-500 text-[16px] leading-[24px] font-bold italic">
            {t("landing.howto.step3")}
          </h3>
          <span className="text-[20px] leading-[28px] font-medium mt-[2px]">
            {t("landing.howto.reward")}
          </span>
          <div className="flex flex-col lg:flex-row space-y-[16px] lg:space-y-0 lg:space-x-[16px] mt-[16px]">
            <div
              className="`
              flex flex-row flex-1 space-x-[8px] p-[14px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px]
              lg:flex-col lg:space-y-[8px] lg:space-x-0 lg:p-[12px]
            `"
            >
              <img
                className="w-[24px] h-[24px]"
                src={isDarkMode ? automaticallyDarkImg : automaticallyImg}
                alt="veTT automatically compounded"
              />
              <span className="self-center lg:self-start text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.compounded")}
              </span>
            </div>
            <div
              className="`
              flex flex-row flex-1 space-x-[8px] p-[14px] bg-neutrals-50 dark:bg-neutrals-800 rounded-[8px]
              lg:flex-col lg:space-y-[8px] lg:space-x-0 lg:p-[12px]
            `"
            >
              <img
                className="w-[24px] h-[24px]"
                src={isDarkMode ? getRewardDarkImg : getRewardImg}
                alt="Get staking reward"
              />
              <span className="self-center lg:self-start text-[14px] leading-[20px] text-black dark:text-white">
                {t("landing.howto.stakingReward")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowtoStake
