import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  updateTotalStakingScore,
  updateStakingRatio,
  updateApy,
  updateEpochLeftTime,
  updateTTPriceUsd,
  updateTokenHolders,
  updateVeTTSupplyAndTotalStaked
} from "../utils/update"

export interface IUserState {
  walletAddress: string
  balance: number
  stakedVeTT: string
  stakeTxHash: string
  unstakeTxHash: string
  claimTxHash: string
  claimAmount: number
  showAddress: boolean
  earnedReward: number
}

export interface IProjectState {
  totalStake: number
  totalStakingScore: number
  stakingRatio: number
  apy: number
  epochLeftTime: number
  blockHeight: number
  ttPriceUsd: number
  veTtSupply: number
  tokenHolders: number
}

export interface IPosState {
  projectStatus: IProjectState
  userStatus: IUserState
}

const initialState: IPosState = {
  projectStatus: {
    totalStake: 0,
    totalStakingScore: 0,
    stakingRatio: 0,
    apy: 0,
    epochLeftTime: 0,
    blockHeight: 0,
    ttPriceUsd: 0,
    veTtSupply: 0,
    tokenHolders: 0
  },
  userStatus: {
    walletAddress: "",
    balance: 0,
    stakedVeTT: "0",
    stakeTxHash: "",
    unstakeTxHash: "",
    claimTxHash: "",
    claimAmount: 0,
    showAddress: false,
    earnedReward: 0
  }
}

const posSlice = createSlice({
  name: "pos",
  initialState,
  reducers: {
    updateProjectStatus: (state, action: PayloadAction<IProjectState>) => {
      state.projectStatus = action.payload
    },
    updateBlockHeight: (state, action: PayloadAction<number>) => {
      state.projectStatus.blockHeight = action.payload
    },
    updateWalletAddress: (state, action: PayloadAction<string>) => {
      state.userStatus.walletAddress = action.payload
    },
    updateWalletBalance: (state, action: PayloadAction<number>) => {
      state.userStatus.balance = action.payload
    },
    updateStakedVeTT: (state, action: PayloadAction<string>) => {
      state.userStatus.stakedVeTT = action.payload
    },
    updateStakeTxHash: (state, action: PayloadAction<string>) => {
      state.userStatus.stakeTxHash = action.payload
    },
    updateUnstakeTxHash: (state, action: PayloadAction<string>) => {
      state.userStatus.unstakeTxHash = action.payload
    },
    updateClaimTxHash: (state, action: PayloadAction<string>) => {
      state.userStatus.claimTxHash = action.payload
    },
    updateClaimAmount: (state, action: PayloadAction<number>) => {
      state.userStatus.claimAmount = action.payload
    },
    updateShowAddress: (state, action: PayloadAction<boolean>) => {
      state.userStatus.showAddress = action.payload
    },
    updateEarnedReward: (state, action: PayloadAction<number>) => {
      state.userStatus.earnedReward = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTotalStakingScore.fulfilled, (state, action) => {
        state.projectStatus.totalStakingScore = action.payload
      })
      .addCase(updateStakingRatio.fulfilled, (state, action) => {
        state.projectStatus.stakingRatio = action.payload
      })
      .addCase(updateApy.fulfilled, (state, action) => {
        state.projectStatus.apy = action.payload
      })
      .addCase(updateEpochLeftTime.fulfilled, (state, action) => {
        state.projectStatus.epochLeftTime = action.payload
      })
      .addCase(updateTTPriceUsd.fulfilled, (state, action) => {
        state.projectStatus.ttPriceUsd = action.payload
      })
      .addCase(updateTokenHolders.fulfilled, (state, action) => {
        state.projectStatus.tokenHolders = action.payload
      })
      .addCase(updateVeTTSupplyAndTotalStaked.fulfilled, (state, action) => {
        state.projectStatus.totalStake = action.payload.totalStake
        state.projectStatus.veTtSupply = action.payload.veTtSupply
      })
  }
})

export const {
  updateProjectStatus,
  updateBlockHeight,
  updateWalletAddress,
  updateWalletBalance,
  updateStakedVeTT,
  updateStakeTxHash,
  updateUnstakeTxHash,
  updateClaimTxHash,
  updateClaimAmount,
  updateShowAddress,
  updateEarnedReward
} = posSlice.actions

export default posSlice.reducer
