import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import { makeStyles } from "@mui/styles"
import { utils } from "ethers"
import { useSelector } from "react-redux"
import { selectPOS } from "../../store/store"
import { IPosState, updateClaimTxHash } from "../../store/posSlice"
import {
  // updateTotalStake,
  updateTotalStakingScore,
  updateStakingRatio,
  updateApy,
  updateEpochLeftTime,
  updateTTPriceUsd,
  // updateVeTTSupply,
  updateVeTTSupplyAndTotalStaked
} from "../../utils/update"
import {
  updateStakedVeTT,
  updateWalletBalance,
  updateStakeTxHash,
  updateUnstakeTxHash,
  updateClaimAmount,
  updateEarnedReward
} from "../../store/posSlice"
import { useAppDispatch } from "../../store/hooks"
import useChainService from "../../hooks/useChainService"
import Modal from "../Body/Modal"
import StakeRequesting from "../Body/ModalContent/Stake/Requesting"
import StakeProcessing from "../Body/ModalContent/Stake/Processing"
import StakeTxResult from "../Body/ModalContent/Stake/TxResult"
import UnstakeRequesting from "../Body/ModalContent/Unstake/Requesting"
import UnstakeProcessing from "../Body/ModalContent/Unstake/Processing"
import UnstakeTxResult from "../Body/ModalContent/Unstake/TxResult"
import ClaimProcessing from "../Body/ModalContent/Claim/Processing"
import ClaimTxResult from "../Body/ModalContent/Claim/TxResult"
import Disclaimer from "../Body/ModalContent/Disclaimer"
import { STORAGE_KEY } from "../../utils/constants"
import useGeoLocation from "react-ipgeolocation"
import StyledTabs from "../Tabs/Tabs"
import Tab from "../Tabs/Tab"
import TabPanel, { a11yProps } from "../Tabs/TabPanel"
import StakeForm from "./StakeForm"
import StakingFormStats from "./StakingFormStats"
import StakingFormUserStats from "./StakingFormUserStats"
import UnStakeForm from "./UnStakeForm"
import GetTTModal from "./ModalContent/GetTT"
import { useTranslation } from "react-i18next"
import useExchangeRatio from "../../hooks/useExchangeRatio"

interface IStakingFormProps extends IStakingFormOwnProps {}

interface IStakingFormOwnProps {}

const StakingForm: React.FC<IStakingFormProps> = () => {
  const classes = useStyles()
  const pos = useSelector(selectPOS)
  const dispatch = useAppDispatch()
  const {
    getUserStakedToken,
    getUserBalance,
    getUserClaimStatus,
    getEarnedRewards,
    stake,
    unstake,
    claim
  } = useChainService()

  const [inputValue, setInputValue] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [checkTWLegalModal, setCheckTWLegalModal] = useState(false)
  const [getTTModal, setGetTTModal] = useState(false)
  const [isStaking, setIsStaking] = useState(false)
  const [isRequestingStake, setIsRequestingStake] = useState(false)
  const [stakedSuccess, setStakedSuccess] = useState(false)
  const [isUnstaking, setIsUnstaking] = useState(false)
  const [isRequestingUnstake, setIsRequestingUnstake] = useState(false)
  const [unstakedSuccess, setUnstakedSuccess] = useState(false)
  const [claimClicked, setClaimClicked] = useState(false)
  const [isClaiming, setIsClaiming] = useState(false)
  const [claimSuccess, setClaimSuccess] = useState(false)
  const [checkTWInit, setCheckTWInit] = useState(false)
  const location = useGeoLocation()
  const [refreshVar, setRefreshVar] = useState(false) // For refresh
  const [tabState, setTabstate] = React.useState(0)
  const { t } = useTranslation()

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabstate(newValue)
    setInputValue("")
  }
  const { totalStake } = pos.projectStatus
  const { walletAddress, showAddress } = pos.userStatus
  const showTwDisclaimer = localStorage.getItem(STORAGE_KEY.SHOW_TW_DISCLAIMER) !== "true"
  useEffect(() => {
    const getStates = async () => {
      dispatch(updateVeTTSupplyAndTotalStaked())
      // dispatch(updateTotalStake())
      dispatch(updateTotalStakingScore())
      dispatch(updateStakingRatio())
      dispatch(updateApy())
      dispatch(updateEpochLeftTime())
      dispatch(updateTTPriceUsd())
      // dispatch(updateVeTTSupply())
    }

    getStates()
  }, [dispatch, walletAddress, totalStake, refreshVar])

  useEffect(() => {
    const updateStateWithAddress = async () => {
      if (walletAddress !== "") {
        dispatch(updateWalletBalance(+utils.formatEther(await getUserBalance(walletAddress))))
        let veTT = await getUserStakedToken(walletAddress)
        if (!veTT) veTT = "0"
        dispatch(updateStakedVeTT(veTT.toString()))

        const ret = await getUserClaimStatus(walletAddress)
        if (ret) dispatch(updateClaimAmount(+utils.formatEther(ret.amount)))

        const rewards = await getEarnedRewards(walletAddress)
        if (rewards) dispatch(updateEarnedReward(rewards))
      }
    }
    updateStateWithAddress()
  }, [walletAddress, dispatch, openModal])

  useEffect(() => {
    if (location && location.country && !checkTWInit) {
      setCheckTWInit(true)
      if (location.country === "TW" && showTwDisclaimer) {
        return setCheckTWLegalModal(true)
      }
    }
  }, [location, setCheckTWInit])

  useEffect(() => {
    const setUserBalance = async () => {
      if (walletAddress !== "") {
        dispatch(updateWalletBalance(+utils.formatEther(await getUserBalance(walletAddress))))
      }
    }
    setUserBalance()
  }, [pos.userStatus])

  const getUnstakeAmount = async () => {
    const ret = await getUserClaimStatus(walletAddress)
    if (ret) dispatch(updateClaimAmount(+utils.formatEther(ret.amount)))
  }

  const updateUserReward = async () => {
    const updateReward = async () => {
      const rewards = await getEarnedRewards(walletAddress)
      if (rewards) dispatch(updateEarnedReward(rewards))
    }
    setTimeout(() => updateReward(), 3000)
  }

  const handleStake = async () => {
    setIsRequestingStake(false)
    setIsStaking(true)

    const ret = await stake(inputValue)

    if (ret) {
      dispatch(updateStakeTxHash(ret.transactionHash))
      setStakedSuccess(true)
    }
    setIsStaking(false)
    setRefreshVar(!refreshVar)
    await updateUserReward()
  }

  const handleUnstake = async () => {
    setIsRequestingUnstake(false)
    setIsUnstaking(true)

    const ret = await unstake(inputValue)

    if (ret) {
      dispatch(updateUnstakeTxHash(ret.transactionHash))
      setUnstakedSuccess(true)
    }
    setIsUnstaking(false)
    await getUnstakeAmount()
    setRefreshVar(!refreshVar)
    await updateUserReward()
  }

  const handleClaim = async () => {
    setOpenModal(true)
    setIsClaiming(true)
    setClaimClicked(true)
    const ret = await claim()

    if (ret) {
      dispatch(updateClaimTxHash(ret.transactionHash))
      setClaimSuccess(true)
    }
    dispatch(updateWalletBalance(+utils.formatEther(await getUserBalance(walletAddress))))
    setIsClaiming(false)
    setRefreshVar(!refreshVar)
    await updateUserReward()
  }

  const handleCloseModal = async () => {
    setStakedSuccess(false)
    setUnstakedSuccess(false)
    setOpenModal(false)
    setInputValue("")
    dispatch(updateWalletBalance(+utils.formatEther(await getUserBalance(walletAddress))))
  }

  const closeClaimModal = async () => {
    setClaimClicked(false)
    setClaimSuccess(false)
    setOpenModal(false)
    dispatch(updateWalletBalance(+utils.formatEther(await getUserBalance(walletAddress))))
    await getUnstakeAmount()
  }
  const handleDisclaimerConfirm = (notShowAgain: boolean) => {
    if (notShowAgain) localStorage.setItem(STORAGE_KEY.SHOW_TW_DISCLAIMER, "true")
    setCheckTWLegalModal(false)
  }

  return (
    <div className="flex flex-col justify-center w-full max-w-[476px] mx-auto">
      {showAddress && (
        <StakingFormUserStats
          pos={pos}
          unstakedSuccess={unstakedSuccess}
          handleClaim={handleClaim}
          setGetTTModal={setGetTTModal}
        />
      )}
      <StyledTabs value={tabState} onChange={handleChangeTab} aria-label="staking tabs">
        <Tab label={t("staking.stake.stake")} {...a11yProps(0)} />
        <Tab label={t("staking.unstake.unstake")} {...a11yProps(1)} />
      </StyledTabs>
      <TabPanel value={tabState} index={0}>
        <StakeForm
          pos={pos}
          inputValue={inputValue}
          setInputValue={setInputValue}
          dispatch={dispatch}
          setIsRequestingStake={setIsRequestingStake}
          setOpenModal={setOpenModal}
        />
      </TabPanel>
      <TabPanel value={tabState} index={1}>
        <UnStakeForm
          pos={pos}
          inputValue={inputValue}
          setInputValue={setInputValue}
          dispatch={dispatch}
          setIsRequestingUnstake={setIsRequestingUnstake}
          setOpenModal={setOpenModal}
        />
      </TabPanel>
      <StakingFormStats />
      <Modal open={openModal} modalContentStyle={classes.modalContainer}>
        {tabState === 0 && !claimClicked ? (
          <StakeModal
            stakeAmount={Number(inputValue)}
            handleStake={handleStake}
            isRequestingStake={isRequestingStake}
            setIsRequestingStake={setIsRequestingStake}
            isStaking={isStaking}
            stakedSuccess={stakedSuccess}
            handleCloseModal={handleCloseModal}
          />
        ) : claimClicked ? (
          <ClaimModal
            pos={pos}
            isClaiming={isClaiming}
            claimSuccess={claimSuccess}
            closeClaimModal={closeClaimModal}
          />
        ) : (
          <UnstakeModal
            unstakeAmount={Number(inputValue)}
            handleUnstake={handleUnstake}
            isRequestingUnstake={isRequestingUnstake}
            setIsRequestingUnstake={setIsRequestingUnstake}
            isUnstaking={isUnstaking}
            unstakedSuccess={unstakedSuccess}
            handleCloseModal={handleCloseModal}
          />
        )}
      </Modal>
      <Modal open={checkTWLegalModal} modalContentStyle={classes.modalContainer}>
        <Disclaimer handleDisclaimerConfirm={handleDisclaimerConfirm} />
      </Modal>
      <Modal open={getTTModal} modalContentStyle={classes.getTTmodalContainer}>
        <GetTTModal setGetTTModal={setGetTTModal} />
      </Modal>
    </div>
  )
}

const StakeModal = (props: {
  stakeAmount: number
  handleStake: () => void
  isRequestingStake: boolean
  setIsRequestingStake: Dispatch<SetStateAction<boolean>>
  isStaking: boolean
  stakedSuccess: boolean
  handleCloseModal: () => void
}) => {
  const {
    stakeAmount,
    handleStake,
    isRequestingStake,
    setIsRequestingStake,
    isStaking,
    stakedSuccess,
    handleCloseModal
  } = props

  const { ttVeTTRatio } = useExchangeRatio()

  const handleCloseRequesting = () => {
    setIsRequestingStake(false)
    handleCloseModal()
  }

  return (
    <div className="relative h-[460px] p-[16px] rounded-[14px] border-solid border-[1px] border-br-light dark:border-neutrals-700 dark:bg-neutrals-900">
      {isRequestingStake ? (
        <StakeRequesting
          ttVeTTRatio={ttVeTTRatio}
          stakeAmount={stakeAmount}
          handleCloseRequesting={handleCloseRequesting}
          handleStake={handleStake}
        />
      ) : isStaking ? (
        <StakeProcessing ttVeTTRatio={ttVeTTRatio} stakeAmount={stakeAmount} />
      ) : (
        <StakeTxResult
          ttVeTTRatio={ttVeTTRatio}
          stakeAmount={stakeAmount}
          stakedSuccess={stakedSuccess}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  )
}

const UnstakeModal = (props: {
  unstakeAmount: number
  handleUnstake: () => void
  isRequestingUnstake: boolean
  setIsRequestingUnstake: Dispatch<SetStateAction<boolean>>
  isUnstaking: boolean
  unstakedSuccess: boolean
  handleCloseModal: () => void
}) => {
  const {
    unstakeAmount,
    handleUnstake,
    isRequestingUnstake,
    setIsRequestingUnstake,
    isUnstaking,
    unstakedSuccess,
    handleCloseModal
  } = props

  const handleCloseRequesting = () => {
    setIsRequestingUnstake(false)
    handleCloseModal()
  }

  const { veTTTtRatio } = useExchangeRatio()

  return (
    <div className="relative h-[460px] p-[16px] rounded-[14px] border-solid border-[1px] border-br-light dark:border-neutrals-700 dark:bg-neutrals-900">
      {isRequestingUnstake ? (
        <UnstakeRequesting
          veTTTtRatio={veTTTtRatio}
          unstakeAmount={unstakeAmount}
          handleCloseRequesting={handleCloseRequesting}
          handleUnstake={handleUnstake}
        />
      ) : isUnstaking ? (
        <UnstakeProcessing veTTTtRatio={veTTTtRatio} unstakeAmount={unstakeAmount} />
      ) : (
        <UnstakeTxResult
          veTTTtRatio={veTTTtRatio}
          unstakeAmount={unstakeAmount}
          unstakedSuccess={unstakedSuccess}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  )
}

const ClaimModal = (props: {
  pos: IPosState
  isClaiming: boolean
  claimSuccess: boolean
  closeClaimModal: () => void
}) => {
  const { pos, claimSuccess, isClaiming, closeClaimModal } = props
  const { claimAmount } = pos.userStatus

  return (
    <div className="relative h-[460px] p-[16px] rounded-[14px] border-solid border-[1px] border-br-light dark:border-neutrals-700 dark:bg-neutrals-900">
      {isClaiming ? (
        <ClaimProcessing claimAmount={claimAmount} />
      ) : (
        <ClaimTxResult
          claimSuccess={claimSuccess}
          claimAmount={claimAmount}
          closeClaimModal={closeClaimModal}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles({
  modalContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    maxWidth: 428,
    transform: "translate(-50%, -50%)",
    border: "0px solid #000",
    borderRadius: 14,
    backgroundColor: "white",
    "@media (max-width: 1220px)": {
      width: "90%"
    }
  },
  getTTmodalContainer: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    maxWidth: 343,
    transform: "translate(-50%, -50%)",
    border: "0px solid #000",
    borderRadius: 14,
    backgroundColor: "white"
  }
})

export default StakingForm
