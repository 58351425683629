import { useMatch, useNavigate } from "react-router-dom"
import React from "react"
import logoDesktop from "../../assets/posheader.png"
import logoMobie from "../../assets/thundercoreIcon.png"
import lightBtn from "../../assets/lightBtn.svg"
import darkBtn from "../../assets/darkBtn.svg"
import ConnectWallet from "./ConnectWallet"
import { ColorModeContext } from "../../context/Color"
import { useBreakpoints } from "../../hooks/useBreakpoints"

interface IHeaderProps extends IIHeaderProps {}

interface IIHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const isMatchStakingPath = useMatch("/staking")
  const navigate = useNavigate()
  const navigateToRoot = () => {
    if (isMatchStakingPath) {
      navigate("/")
    }
  }
  const colorMode = React.useContext(ColorModeContext)
  const { isDarkMode, enable, disable } = colorMode
  const { isLG, isXL } = useBreakpoints()

  return (
    <div className="w-full bg-black border-b-[1px] border-solid border-neutrals-700">
      <div className="flex justify-between w-full max-w-[1440px] mx-auto bg-black py-[20px] px-[24px] md:px-[40px]">
        <img
          className="w-[32px] h-[32px] lg:w-[156px] lg:h-[40px] self-center cursor-pointer"
          src={isLG || isXL ? logoDesktop : logoMobie}
          alt="logo"
          onClick={navigateToRoot}
        />
        <div className="flex items-center space-x-[16px]">
          {isMatchStakingPath && <ConnectWallet />}
          {isDarkMode ? (
            <div className="cursor-pointer" onClick={disable}>
              <img className="w-[40px] h-[40px]" src={lightBtn} alt="light" />
            </div>
          ) : (
            <div className="cursor-pointer" onClick={enable}>
              <img className="w-[40px] h-[40px]" src={darkBtn} alt="dark" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
