import axios from "axios"
import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  getTotalStakingScore,
  getStakingRatio,
  getAPY,
  timeTillNextReward,
  getTTPrice,
  getTokenHolders,
  getVeTTSupplyAndTotalStaked
} from "./chainService"
import config from "../config"

const handleError = (result: any) => {
  if (result.data.error) {
    return Promise.reject(result.data.error)
  }
  return result
}

export const getBlockNumber = async (): Promise<number> => {
  const result = await axios.post(config.rpcUrl, {
    id: Math.floor(Math.random() * 100000000),
    jsonrpc: "2.0",
    method: "eth_blockNumber",
    params: []
  })
  const { data } = await handleError(result)
  return parseInt(data.result, 16)
}

export const getCirculatingSupply = async (): Promise<number> => {
  if (config.circulatingSupplyUrl === "circulatingSupplyUrlNotSet") {
    return +config.totalSupplyBeforeHardfork
  }
  const result = await axios.get(config.circulatingSupplyUrl)
  const { data } = await handleError(result)
  return data
}

export const updateTotalStakingScore = createAsyncThunk(
  "pos/updateTotalStakingScore",
  async () => await getTotalStakingScore()
)

export const updateStakingRatio = createAsyncThunk(
  "pos/updateStakingRatio",
  async () => await getStakingRatio()
)

export const updateApy = createAsyncThunk("pos/updateApy", async () => await getAPY())

export const updateEpochLeftTime = createAsyncThunk(
  "pos/updateEpochLeftTime",
  async () => Date.now() + (await timeTillNextReward())
)

export const updateTTPriceUsd = createAsyncThunk("pos/updateTTPriceUsd", async () =>
  Number(await getTTPrice())
)

export const updateTokenHolders = createAsyncThunk("pos/updateTokenHolders", async () =>
  Number(await getTokenHolders())
)

export const updateVeTTSupplyAndTotalStaked = createAsyncThunk(
  "pos/updateVeTTSupplyAndTotalStaked",
  async () => await getVeTTSupplyAndTotalStaked()
)
