import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { selectPOS } from "../store/store"

const useExchangeRatio = () => {
  const {
    projectStatus: { totalStake, veTtSupply }
  } = useSelector(selectPOS)
  const [ttVeTTRatio, setTtVeTTRatio] = useState<number>(0)
  const [veTTTtRatio, setVeTTTtRatio] = useState<number>(0)

  useEffect(() => {
    setTtVeTTRatio(totalStake ? veTtSupply / totalStake : 0)
    setVeTTTtRatio(veTtSupply ? totalStake / veTtSupply : 0)
  }, [totalStake, veTtSupply])

  return { ttVeTTRatio, veTTTtRatio }
}

export default useExchangeRatio
