export interface IFooterProps extends IFooterOwnProps {}

export interface IFooterOwnProps {}

export interface ITextLink {
  text: string
  src: string
  openBlank: boolean
}

export interface ICategory {
  title: string
  linkList: ITextLink[]
}

const categoryList: ICategory[] = [
  {
    title: "Developers",
    linkList: [
      {
        text: "Get Started",
        src: "https://www.thundercore.com/developers/",
        openBlank: true
      },

      {
        text: "Documentation",
        src: "https://docs.developers.thundercore.com/",
        openBlank: true
      },
      {
        text: "Block Explorer",
        src: "https://explorer-mainnet.thundercore.com/",
        openBlank: true
      },
      {
        text: "Github",
        src: "https://github.com/thundercore",
        openBlank: true
      },
      {
        text: "Growth Fund",
        src: "https://thundertoken.notion.site/10-Million-Growth-Fund-d7fcb89c063541eaa1740292a4ae98d6",
        openBlank: true
      }
    ]
  },
  {
    title: "Ecosystem",
    linkList: [
      {
        text: "All Apps",
        src: "https://www.thundercore.com/ecosystem",
        openBlank: true
      },
      {
        text: "PoS Staking",
        src: "https://pos-staking.thundercore.com/",
        openBlank: true
      },
      {
        text: "TT Wallet",
        src: "https://www.thundercore.com/",
        openBlank: true
      }
    ]
  },
  {
    title: "Community",
    linkList: [
      // {
      //   text: "Community Hub",
      //   src: "https://www.thundercore.com/community/",
      //   openBlank: true
      // },
      {
        text: "Blogs",
        src: "https://blog.thundercore.com/",
        openBlank: true
      },
      {
        text: "Newsletter",
        src: "https://mailchi.mp/thundercore/subscribe",
        openBlank: true
      }
    ]
  },
  {
    title: "About",
    linkList: [
      // {
      //   text: "Roadmap",
      //   src: "https://www.thundercore.com/roadmap/",
      //   openBlank: true
      // },
      {
        text: "White Paper",
        src: "https://docs.thundercore.com/thunder-whitepaper.pdf",
        openBlank: true
      },
      // {
      //   text: "Media Kits",
      //   src: "https://drive.google.com/drive/folders/19Ugi2zNxqBGHEpESFpjOWnDj7q06n9kT",
      //   openBlank: true
      // },
      {
        text: "Help Center",
        src: "https://help.thundercore.com/hc/en-us",
        openBlank: true
      },
      {
        text: "Contact Us",
        src: "https://help.thundercore.com/hc/en-us/requests/new",
        openBlank: true
      },
      {
        text: "Terms and Privacy",
        src: "https://www.thundercore.com/documents/tc.pdf",
        openBlank: true
      }
    ]
  }
]

export default categoryList
