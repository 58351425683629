import React from "react"
// import Discord from "../../assets/Discord.png"
import Twitter from "../../assets/Twitter.png"
import Telegram from "../../assets/Telegram.png"
import Naverblog from "../../assets/Naverblog.png"
import TwitterHover from "../../assets/TwitterHover.png"
import TelegramHover from "../../assets/TelegramHover.png"
import NaverblogHover from "../../assets/NaverblogHover.png"
// import DiscordHover from "../../assets/DiscordHover.png"

import config from "../../config/index"
import I18nMenu from "../Menu/i18nMenu"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import MobileCategory from "./MobileCategory"
import DesktopCategory from "./DesktopCategory"

interface IFooterProps extends IFooterOwnProps {}

interface IFooterOwnProps {}

interface ICommunityLink {
  image: string
  src: string
  hoverImage: string
}

const communityList: ICommunityLink[] = [
  {
    image: Twitter,
    src: config.twitter,
    hoverImage: TwitterHover
  },
  // {
  //   image: Discord,
  //   src: config.discord,
  //   hoverImage: DiscordHover
  // },
  {
    image: Telegram,
    src: config.telegram,
    hoverImage: TelegramHover
  },
  {
    image: Naverblog,
    src: config.naverBlog,
    hoverImage: NaverblogHover
  }
]

const Footer: React.FC<IFooterProps> = () => {
  const { isTablet } = useBreakpoints()

  return (
    <div className="w-full h-full bg-black">
      {isTablet ? <MobileCategory /> : <DesktopCategory />}
      <div className="flex flex-row justify-between items-center px-[24px] pb-[24px] pt-[56px] md:px-[80px] md:py-[40px] max-w-[1440px] mx-auto">
        <div className="flex flex-row items-center space-x-[32px]">
          {communityList.map((x, i) => (
            <CommunityLink key={`community_link_${i}`} {...x} />
          ))}
        </div>
        <I18nMenu />
      </div>
    </div>
  )
}

const CommunityLink = (props: ICommunityLink) => {
  const { image, src, hoverImage } = props
  return (
    <a href={src} target="_blank" rel="noopener noreferrer">
      <img
        src={image}
        alt={"community"}
        className="w-[24px] h-[24px]"
        onMouseOver={(e) => (e.currentTarget.src = hoverImage)}
        onMouseOut={(e) => (e.currentTarget.src = image)}
      />
    </a>
  )
}

export default Footer
