import { createBreakpoint } from "react-use"

const useBreakpoint = createBreakpoint({ xl: 1440, lg: 1024, md: 720, sm: 375 })

export const useBreakpoints = () => {
  // use window.innerWidth to get the breakpoint
  // breakpoint is one of 'xl', 'lg', 'md', 'sm'
  const breakpoint = useBreakpoint()

  return {
    isXL: breakpoint === "xl",
    isLG: breakpoint === "lg",
    isMd: breakpoint === "md",
    isS: breakpoint === "sm",
    isTablet: breakpoint !== "lg" && (breakpoint === "md" || breakpoint === "sm"),
    isMobile: breakpoint !== "md" && breakpoint === "sm"
  }
}
