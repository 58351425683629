import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import creditcardEnImg from "../../../assets/getTT/creditcardEn.png"
import creditcardKoImg from "../../../assets/getTT/creditcardKo.png"
import multichainEnImg from "../../../assets/getTT/multichainEn.png"
import multichainKoImg from "../../../assets/getTT/multichainKo.png"
import sushiSwapEnImg from "../../../assets/getTT/sushiSwapEn.png"
import sushiSwapKoImg from "../../../assets/getTT/sushiSwapKo.png"
import closeImg from "../../../assets/getTT/close.svg"
import config from "../../../config/index"

interface IGetTTProps extends IGetTTOwnProps {
  setGetTTModal: Dispatch<SetStateAction<boolean>>
}

interface IGetTTOwnProps {}

const GetTT: React.FC<IGetTTProps> = (props: IGetTTProps) => {
  const { i18n, t } = useTranslation()
  const { setGetTTModal } = props
  const creditcardImg = i18n.language.toUpperCase() === "KO" ? creditcardKoImg : creditcardEnImg
  const multichainImg = i18n.language.toUpperCase() === "KO" ? multichainKoImg : multichainEnImg
  const sushiSwapImg = i18n.language.toUpperCase() === "KO" ? sushiSwapKoImg : sushiSwapEnImg

  const handleClick = (type: string) => {
    switch (type) {
      case "card":
        window.open(config.getTTcard, "_blank")
        break
      case "bridge":
        window.open(config.getTTbridge, "_blank")
        break
      case "dex":
        window.open(config.getTTdex, "_blank")
        break
    }
  }
  return (
    <div className="relative px-[24px] py-[32px] rounded-[14px] border-solid border-[1px] border-br-light dark:border-neutrals-700 dark:bg-neutrals-900">
      <img
        src={closeImg}
        onClick={() => {
          setGetTTModal(false)
        }}
        className="absolute top-[16px] right-[16px] w-[24px] h-[24px] cursor-pointer"
        alt="close modal"
      />
      <div className="flex flex-col items-center px-[24px]">
        <div className="text-center text-[24px] leading-[32px] text-black dark:text-white font-semibold">
          {t("staking.getTT.title")}
        </div>
        <div className="text-center mt-[8px] text-[14px] leading-[20px] text-black dark:text-white">
          {t("staking.getTT.desc")}
        </div>
      </div>
      <img
        src={creditcardImg}
        onClick={() => {
          handleClick("card")
        }}
        className="w-auto h-[84px] mx-auto mt-[24px] cursor-pointer"
        alt="creditcard"
      />
      <div className="relative justify-center flex flex-col mt-[24px] h-[20px]">
        <div className="w-full border-t-[1px] border-solid border-neutrals-700"></div>
        <span
          className={`
          w-[167px] text-center text-[12px] leading-[20px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
          text-secondary dark:text-white bg-white dark:bg-neutrals-900
        `}
        >
          {t("staking.getTT.already")}
        </span>
      </div>
      {/* <img
        src={multichainImg}
        onClick={() => {
          handleClick("bridge")
        }}
        className="w-auto h-[84px] mx-auto mt-[12px] cursor-pointer"
        alt="multichain"
      /> */}
      <img
        src={sushiSwapImg}
        onClick={() => {
          handleClick("dex")
        }}
        className="w-auto h-[84px] mx-auto mt-[16px] cursor-pointer"
        alt="sushiSwap"
      />
    </div>
  )
}

export default GetTT
