import React from "react"

import StyledTooltip from "../StyledTooltip/StyledTooltip"

import infoIcon from "../../../assets/Info.svg"

const StakingStatsTooltip = ({
  showTip,
  setShowTip,
  tipContent
}: {
  showTip: boolean
  setShowTip: (value: boolean) => void
  tipContent: string
}) => {
  return (
    <StyledTooltip
      title={tipContent}
      open={showTip}
      leaveTouchDelay={10000}
      onOpen={() => setShowTip(true)}
      onClose={() => setShowTip(false)}
      placement="bottom-start"
    >
      <img
        className="w-[20px] h-[20px] ml-[4px]"
        onClick={() => setShowTip(!showTip)}
        src={infoIcon}
        alt={"info"}
      />
    </StyledTooltip>
  )
}

export default StakingStatsTooltip
