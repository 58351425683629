import React from "react"
import bgDesktop from "../assets/landing/bgDesktop.png"
import bgMobile from "../assets/landing/bgMobile.png"
import bgDesktopDark from "../assets/landing/bgDesktopDark.png"
import bgMobileDark from "../assets/landing/bgMobileDark.png"
import { ColorModeContext } from "../context/Color"
import { useBreakpoints } from "../hooks/useBreakpoints"
import StakingStats from "../components/Landing/StakingStats"
import { useNavigate } from "react-router-dom"
import thunderImg from "../assets/thunder.svg"
import WhyStake from "../components/Landing/WhyStake"
import HowtoStake from "../components/Landing/HowtoStake"
import { useTranslation } from "react-i18next"

interface ILandingContainerProps extends ILandingContainerOwnProps {}

interface ILandingContainerOwnProps {}

const LandingContainer: React.FC<ILandingContainerProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const colorMode = React.useContext(ColorModeContext)
  const { isDarkMode } = colorMode
  const { isS, isTablet } = useBreakpoints()
  const [bg, setBg] = React.useState(() => {
    if (isS) {
      return isDarkMode ? bgMobileDark : bgMobile
    }
    return isDarkMode ? bgDesktopDark : bgDesktop
  })

  React.useEffect(() => {
    if (isS) {
      setBg(isDarkMode ? bgMobileDark : bgMobile)
    } else {
      setBg(isDarkMode ? bgDesktopDark : bgDesktop)
    }
  }, [isDarkMode, isS])

  return (
    <main className="w-full h-full bg-neutrals-50 dark:bg-black flex flex-col items-center">
      <section className="relative w-full h-full max-h-[720px] mb-[32px] md:mb-[40px] lg:mb-[48px]">
        {!isDarkMode && (
          <div className="absolute z-1 bg-primary-700 w-screen h-[364px] md:h-[474px] top-0 left-0"></div>
        )}
        <div
          className="relative z-2 w-full px-[24px] max-w-[1280px] flex flex-col items-center mx-auto bg-no-repeat bg-top"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: `${isS ? "720px 480px" : "1280px 720px"}`
          }}
        >
          <h1
            className="`
              mt-[64px] text-[34px] leading-[44px] text-center font-bold font-mont text-white
              md:mt-[120px] md:text-[56px] md:leading-[68px]
            `"
          >
            {t("landing.first.title")}
          </h1>
          <p
            className="`
              max-w-[327px] mt-[12px] text-[16px] leading-[24px] text-center text-white
              md:max-w-[588px] md:mt-[24px] md:text-[24px] md:leading-[32px] md:font-bold
            `"
          >
            {t("landing.first.subtitle1")}
            {!isTablet && <br />}
            <span className="font-medium italic text-secondary-500">
              &nbsp;{t("landing.first.subtitle2")}
            </span>
            .
          </p>
          <p
            className="`
              max-w-[327px] text-[16px] leading-[24px] text-center font-medium text-white
              md:max-w-[588px] md:mt-[12px] md:text-[20px] md:leading-[28px] md:font-normal
            `"
          >
            {t("landing.first.subtitle3")}
          </p>
          <StakingStats />
          <button
            onClick={() => navigate("/staking")}
            className="btn btn-primary max-w-[410px] w-full h-[56px] mt-[24px] lg:mt-[48px]"
          >
            {t("landing.first.button")}
          </button>
          <img
            src={thunderImg}
            className="w-[20px] h-[32px] mt-[48px] md:mt-[56px] mx-auto"
            alt="thunder"
          />
        </div>
      </section>
      <section className="w-full mb-[48px] lg:mb-[80px] px-[24px]">
        <h2
          className="`
          mb-[24px] text-[24px] leading-[32px] font-semibold text-primary-700 dark:text-white text-center font-mont
          lg:mb-[32px] lg:text-[34px] lg:leading-[48px] lg:font-bold
        `"
        >
          {t("landing.why.title")}
        </h2>
        <WhyStake isDarkMode={isDarkMode} />
      </section>
      <section className="flex flex-col w-full mb-[24px] lg:mb-[32px] px-[24px]">
        <h2
          className="`
          mb-[24px] text-[24px] leading-[32px] font-semibold text-primary-700 dark:text-white text-center font-mont
          lg:mb-[32px] lg:text-[34px] lg:leading-[48px] lg:font-bold
        `"
        >
          {t("landing.howto.title")}
        </h2>
        <HowtoStake isDarkMode={isDarkMode} />
        <button
          onClick={() => navigate("/staking")}
          className="btn btn-secondary max-w-[375px] w-full h-[48px] mx-auto mt-[24px] md:mt-[48px] lg:mt-[56px]"
        >
          {t("landing.first.button")}
        </button>
        <div
          onClick={() => {
            window.open(
              "https://blog.thundercore.com/10-things-you-need-to-know-about-thundercore-2-0-pos-staking-5ffdbce68c4d",
              "_blank"
            )
          }}
          className="mt-[28px] mb-[76px] md:mb-[92px] lg:mt-[36px] dark:text-white dark:hover:text-neutrals-200 text-center underline cursor-pointer font-mont"
        >
          {t("landing.howto.readmore")}
        </div>
      </section>
    </main>
  )
}

export default LandingContainer
