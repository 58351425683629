import React, { CSSProperties, useEffect, useRef, useState } from "react"
import MetaMaskLogo from "../../assets/metamask.svg"
import WalletConnectLogo from "../../assets/walletconnect-circle.svg"
import config from "../../config"
import { useWalletWrapper } from "../../context/Wallet"
import { useAppDispatch } from "../../store/hooks"
import { updateWalletAddress, updateShowAddress } from "../../store/posSlice"

export interface IProviderDisplay {
  name: string
  logo: string
  description?: string
}

export interface IProviderInfo extends IProviderDisplay {
  id: string
  type: string
  check: string
  package?: IProviderPackageOptions
}

export type RequiredOption = string | string[]

export interface IProviderPackageOptions {
  required?: RequiredOption[]
}

export const WALLETCONNECT: IProviderInfo = {
  id: "walletconnect",
  name: "WalletConnect",
  logo: WalletConnectLogo,
  type: "qrcode",
  check: "isWalletConnect",
  package: {
    required: [["infuraId", "rpc"]]
  }
}

export const METAMASK: IProviderInfo = {
  id: "injected",
  name: "MetaMask",
  logo: MetaMaskLogo,
  type: "injected",
  check: "isMetaMask"
}

interface IWeb3Modal {
  opacity: number
  show: number
  offset: number
  maxWidth?: number
  themeColors: {
    background?: string
    color?: string
    borderColor?: string
    descColor?: string
    hover?: string
  }
  zIndex?: number
  isMobile: boolean
}

const generateStyles = ({
  offset,
  zIndex,
  opacity,
  show,
  isMobile,
  maxWidth,
  themeColors
}: IWeb3Modal) => ({
  sLightbox: {
    transition: "opacity 0.1s ease-in-out",
    textAlign: "center",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    marginLeft: "-50vw",
    top: offset ? `-${offset}px` : 0,
    left: "50%",
    zIndex: zIndex || 1,
    willChange: "opacity",
    backgroundColor: `rgba(0, 0, 0, ${typeof opacity === "number" ? opacity : 0.4})`,
    opacity: show ? 1 : 0,
    visibility: show ? "visible" : "hidden",
    pointerEvents: show ? "auto" : "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
    // & * {
    //   boxSizing: border-box !important;
    // }
  },
  sModalContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: show ? 1 : 0,
    visibility: show ? "visible" : "hidden",
    pointerEvents: show ? "auto" : "none"
  },
  SModalCard: {
    position: "relative",
    width: "100%",
    backgroundColor: themeColors.background || "white",
    borderRadius: "12px",
    margin: "10px",
    padding: "0",
    opacity: show ? 1 : 0,
    visibility: show ? "visible" : "hidden",
    pointerEvents: show ? "auto" : "none",
    display: "grid",
    gridTemplateColumns: isMobile ? "1fr" : "repeat(auto-fit, minmax(320px, 1fr))",
    maxWidth: `${maxWidth || isMobile ? "500" : "800"}px`,
    minWidth: "fit-content",
    maxHeight: "100%",
    overflow: "auto"
  },
  sIcon: {
    width: isMobile ? "8.5vw" : "45px",
    height: isMobile ? "8.5vw" : "45px",
    display: "flex",
    borderRadius: "50%",
    overflow: "visible",
    boxShadow: "none",
    justifyContent: "center",
    alignItems: "center",
    "&img": {
      width: "100%",
      height: "100%"
    }
  },
  sName: {
    width: "100%",
    fontSize: isMobile ? "5vw" : "24px",
    fontWeight: 700,
    marginTop: "0.5em",
    marginBottom: "0",
    color: themeColors.color || "black"
  },
  sProviderContainer: {
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: themeColors.background || "white",
    borderRadius: "12px",
    border: "none",
    padding: isMobile ? "1vw" : "24px 16px"
  },
  sDescription: {
    width: "100%",
    fontSize: isMobile ? "4vw" : "18px",
    margin: "0.333em 0",
    color: themeColors.descColor || "black"
  },
  sProviderWrapper: {
    padding: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    borderRadius: "0",
    backgroundColor: themeColors.background || "white",
    border: `1px solid ${themeColors.borderColor}`
    // '&:hover': {
    //   backgroundColor: themeColors.hover || 'gray',
    // },
  }
})

const getProviderDescription = (providerInfo: Partial<IProviderInfo>): string => {
  if (providerInfo.description) {
    return providerInfo.description
  }
  let description = ""
  switch (providerInfo.type) {
    case "injected":
      description = `Connect to your ${providerInfo.name} Wallet`
      break
    case "web":
      description = `Connect with your ${providerInfo.name} account`
      break
    case "qrcode":
      description = `Scan with ${providerInfo.name} to connect`
      break
    case "hardware":
      description = `Connect to your ${providerInfo.name} Hardware Wallet`
      break
    default:
      break
  }
  return description
}

const verifyInjectedProvider = (check: string): boolean => {
  return window.ethereum
    ? //@ts-ignore
      window.ethereum[check]
    : false
}

const injectedWallets = [METAMASK]
const otherSupportedWallets = [WALLETCONNECT]

interface IStylesConfig {
  opacity: number
  show: number
  offset: number
  themeColors: {
    borderColor: string
    hover: string
    descColor: string
  }
  zIndex: number
}
interface IConnectWalletsModal {
  stylesConfig?: IStylesConfig
  connect: (type: string) => void
  open: boolean
  closeModal: () => void
  children?: any
}

interface IStyleComponents {
  [key: string]: CSSProperties
}

export default function ConnectWalletsModal({
  open,
  closeModal,
  connect,
  stylesConfig
}: IConnectWalletsModal) {
  const [supportedWallets, setsupportedWallets] = useState<IProviderInfo[]>([])
  const cardRef = useRef<HTMLDivElement>(null)
  const isMobile = !window.matchMedia("(min-width: 768px)").matches
  const baseStyles = stylesConfig || {
    opacity: 0.7,
    show: 1,
    offset: 0,
    themeColors: {
      borderColor: "rgba(195, 195, 195, 0.14)",
      hover: "rgba(195, 195, 195, 0.14)",
      descColor: "rgb(169, 169, 188)"
    },
    zIndex: 1101,
    isMobile
  }
  const styles = generateStyles({ ...baseStyles, isMobile }) as IStyleComponents
  const dispatch = useAppDispatch()

  const autoSwitchNetwork = () => {
    const network = Number(config.chainId) || 18
    const networkInfo: { [key: number]: any } = {
      108: {
        chainId: "0x6c",
        chainName: "Thundercore Mainnet",
        rpcUrls: ["https://mainnet-rpc.thundercore.com"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      },
      18: {
        chainId: "0x12",
        chainName: "Thundercore Testnet",
        rpcUrls: ["https://testnet-rpc.thundercore.com"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      },
      19: {
        chainId: "0x13",
        chainName: "Thundercore Testnet QA02",
        rpcUrls: ["https://qa02-rpc.dev.tt-eng.com/"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      }
    }
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [networkInfo[network]] // you must have access to the specified account
        })
        .catch((error: any) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log("We can encrypt anything without the key.")
          } else {
            console.error(error)
          }
        })
    }
  }

  const handleConnect = (providerInfo: IProviderInfo) => {
    // console.log('providerInfo:', providerInfo)
    connect(providerInfo.id)
    if (providerInfo.id === "injected") autoSwitchNetwork()
    closeModal()
  }
  const { account } = useWalletWrapper()

  // useOnClickOutside
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const isInsider = !cardRef.current || cardRef.current.contains(event.target as Node)
      if (!isInsider) {
        closeModal()
      }
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [cardRef, closeModal])

  useEffect(() => {
    if (account && account.length > 0) {
      dispatch(updateWalletAddress(account.toLocaleLowerCase()))
      dispatch(updateShowAddress(true))
    }
  }, [account, dispatch])

  useEffect(() => {
    const injectedList = injectedWallets.filter((wallet) => verifyInjectedProvider(wallet.check))
    setsupportedWallets(
      injectedList.length === 1
        ? [...injectedList, ...otherSupportedWallets]
        : otherSupportedWallets
    )
  }, [])

  return (
    <>
      {open && (
        <div style={styles.sLightbox}>
          <div style={styles.sModalContainer}>
            <div ref={cardRef} style={styles.SModalCard}>
              {supportedWallets.map((wallet) => (
                <div key={wallet.name} style={styles.sProviderWrapper}>
                  <button style={styles.sProviderContainer} onClick={() => handleConnect(wallet)}>
                    <img style={styles.sIcon} src={wallet.logo} alt={"name"} />
                    <h5 style={styles.sName}>{wallet.name}</h5>
                    <p style={styles.sDescription}>{getProviderDescription(wallet)}</p>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
