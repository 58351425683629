import { useEffect } from "react"
import { useLocalStorage, useMedia } from "react-use"

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)"

interface UseDarkModeOutput {
  isDarkMode: boolean
  toggle: () => void
  enable: () => void
  disable: () => void
}

function useDarkMode(defaultValue?: boolean): UseDarkModeOutput {
  const isDarkOS = useMedia(COLOR_SCHEME_QUERY)
  const [isDarkMode, setDarkMode] = useLocalStorage<boolean>(
    "tt-dark-mode",
    defaultValue ?? isDarkOS ?? false
  )

  useEffect(() => {
    // init dark mode
    if (isDarkMode) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }
  }, [])

  return {
    isDarkMode: isDarkMode ?? false,
    toggle: () => setDarkMode((prev) => !prev),
    enable: () => {
      setDarkMode(true)
      document.documentElement.classList.add("dark")
    },
    disable: () => {
      setDarkMode(false)
      document.documentElement.classList.remove("dark")
    }
  }
}

export default useDarkMode
