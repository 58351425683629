import { configureStore } from "@reduxjs/toolkit"
import posReducer from "./posSlice"

export const store = configureStore({
  reducer: {
    pos: posReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

export const selectPOS = (state: RootState) => state.pos

export type AppDispatch = typeof store.dispatch
