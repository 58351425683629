import React from "react"

import earnImg from "../../assets/landing/landingEarn.png"
import networkImg from "../../assets/landing/landingNetwork.png"
import reinvestImg from "../../assets/landing/landingReinvestment.png"
import secureImg from "../../assets/landing/landingSecure.png"
import earnDarkImg from "../../assets/landing/landingEarnDark.png"
import networkDarkImg from "../../assets/landing/landingNetworkDark.png"
import reinvestDarkImg from "../../assets/landing/landingReinvestmentDark.png"
import secureDarkImg from "../../assets/landing/landingSecureDark.png"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { useTranslation } from "react-i18next"

interface IWhyStakeProps extends IWhyStakeOwnProps {}

interface IWhyStakeOwnProps {
  isDarkMode: boolean
}

interface IWhyStakeOptions {
  imgUrl: string
  description: string
}

const WhyStake: React.FC<IWhyStakeProps> = (props) => {
  const { t } = useTranslation()
  const { isDarkMode } = props
  const { isMd } = useBreakpoints()
  const options: IWhyStakeOptions[] = [
    {
      imgUrl: isDarkMode ? secureDarkImg : secureImg,
      description: t("landing.why.reliable")
    },
    {
      imgUrl: isDarkMode ? earnDarkImg : earnImg,
      description: t("landing.why.earn")
    },
    {
      imgUrl: isDarkMode ? networkDarkImg : networkImg,
      description: t("landing.why.network")
    },
    {
      imgUrl: isDarkMode ? reinvestDarkImg : reinvestImg,
      description: t("landing.why.reinvestment")
    }
  ]
  return (
    <div
      className="`
      flex flex-col justify-center flex-wrap gap-[16px] mx-auto
      md:flex-row md:max-w-[476px] 
      lg:gap-0 lg:flex-nowrap lg:space-x-[24px] lg:max-w-none	
    `"
    >
      {options.map((x, i) => {
        return (
          <div
            key={`why_stake_${i}`}
            className="`
            flex flex-row items-center w-full space-x-[16px] p-[16px] rounded-[18px] bg-white dark:bg-neutrals-900
            md:px-[24px] md:w-[230px] md:h-[80px]
            lg:flex-col lg:w-[180px] lg:h-[180px] lg:space-y-[12px] lg:space-x-0 lg:p-[24px]
          `"
          >
            {!isMd && (
              <img
                src={x.imgUrl}
                alt={x.description}
                className="w-[40px] h-[40px] lg:w-[64px] lg:h-[64px]"
              />
            )}
            <p
              className="`
              text-[16px] leading-[24px] text-left flex-1
              lg:text-[20px] lg:leading-[28px] lg:text-center font-medium
            `"
            >
              {x.description}
            </p>
            {isMd && (
              <img
                src={x.imgUrl}
                alt={x.description}
                className="w-[40px] h-[40px] lg:w-[64px] lg:h-[64px]"
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default WhyStake
