import { ethers, utils } from "ethers"
import { Multicall, ContractCallResults, ContractCallContext } from "ethereum-multicall"

import config, { stakingHelperCtorArg } from "../config"
import oracleContractAbi from "@thundercore/oracle/abi/DataFeedConsumerInterface.json"
import PosStakingAbi from "../constant/abi/pos-staking-contract-abi.json"
import { JsonRpcProvider } from "@ethersproject/providers"

import { StakingHelper } from "tt-staking-lib"
import { GAS_FEE_MULTIPLIER } from "../constant/constants"
import { getCirculatingSupply } from "./update"

const stakingHelper = new StakingHelper(stakingHelperCtorArg(config))

const chainJsonRpcProvider = new ethers.providers.JsonRpcProvider(config.rpcUrl)
const multicall = new Multicall({
  ethersProvider: chainJsonRpcProvider,
  tryAggregate: true,
  multicallCustomContractAddress:
    config.chainId === "108"
      ? "0xcA11bde05977b3631167028862bE2a173976CA11"
      : "0x9f5A50e297a43a26e04d05501e492D8a1cB08936"
})

let contractInstance = new ethers.Contract(
  config.stakingContractAddress,
  PosStakingAbi.abi,
  chainJsonRpcProvider
)

const oracleContract = new ethers.Contract(
  config.oracleContractAddress,
  oracleContractAbi,
  chainJsonRpcProvider
)

export function updatePOSContract(library: any) {
  contractInstance = new ethers.Contract(
    config.stakingContractAddress,
    PosStakingAbi.abi,
    getProviderOrSigner(library)
  )
}

export async function getPOSContract() {
  return contractInstance
}

export function getProviderOrSigner(library: any): any {
  if (library instanceof JsonRpcProvider) return library
  const provider = getProvider(library)
  const signer = provider.getSigner().connectUnchecked()
  return signer
}

export function getProvider(library: any) {
  if (library instanceof JsonRpcProvider) return library
  return new ethers.providers.Web3Provider(library)
}

export async function getTotalStakingScore() {
  const currentBlockHeight = await getBlockHeight()
  const posBlockHeight = parseInt(config.posBlockHeight)
  const contract = await getPOSContract()

  return currentBlockHeight > posBlockHeight
    ? +utils.formatEther(await stakingHelper.getTotalStakingScore())
    : parseInt(utils.formatEther(await contract.getTTPool()))
}

export async function getStakingRatio() {
  const currentBlockHeight = await getBlockHeight()
  const posBlockHeight = parseInt(config.posBlockHeight)
  const totalStake = await getTotalStakingScore()
  const circulatingSupply = await getCirculatingSupply()
  return (
    totalStake /
    (currentBlockHeight > posBlockHeight ? circulatingSupply : +config.totalSupplyBeforeHardfork)
  )
}

export async function getAPY() {
  try {
    const currentBlockHeight = await getBlockHeight()
    const posBlockHeight = parseInt(config.posBlockHeight)
    const totalStake = Math.max(await getTotalStakingScore(), 1)
    const epochReturn = 145800 / totalStake

    if (currentBlockHeight < posBlockHeight) {
      return Math.pow(1 + epochReturn, 8 * 365) - 1
    }
    const apy = +(await stakingHelper.getAPY())
    return apy === 0 ? Math.pow(1 + epochReturn, 8 * 365) - 1 : apy
  } catch (error) {
    console.error("get APY failed.", error)
    return 0
  }
}

export async function getBlock(height: number) {
  return await chainJsonRpcProvider.getBlock(height)
}

export async function timeTillNextReward() {
  try {
    const currentBlockHeight = await getBlockHeight()
    const currentBlockNonce = +(await getBlock(currentBlockHeight)).nonce
    const lastSessionLength = +(await getBlock(currentBlockHeight - currentBlockNonce)).nonce
    return (lastSessionLength - currentBlockNonce) * +config.avgMillisecondPerBlock
  } catch (err) {
    console.error("get time till next reward failed.", err)
    return 0
  }
}

export async function getTTPrice() {
  try {
    const { answer } = await oracleContract.latestRoundData()

    return utils.formatEther(answer)
  } catch (error) {
    console.error("Get TT price failed.", error)
    return "0"
  }
}

export async function getTokenHolders() {
  try {
    const { result } = await stakingHelper.getTokenHolders(config.stakingContractAddress)
    return result.count
  } catch (error) {
    console.error("getTokenHolders failed.", error)
    return 0
  }
}

export async function getBlockHeight() {
  return await chainJsonRpcProvider.getBlockNumber()
}

export async function getMaxBalanceEstimateGasFee(value: number) {
  try {
    const contract = await getPOSContract()
    const gasPrice = await chainJsonRpcProvider.getGasPrice()
    const gasLimit = await contract.estimateGas.enter({
      value: utils.parseEther(Math.floor(value).toString())
    })
    const gasfee = gasLimit.mul(gasPrice).mul(Math.round(GAS_FEE_MULTIPLIER))
    return +utils.formatEther(gasfee)
  } catch (error) {
    console.error("getMaxBalanceEstimateGasFee failed.", error)
    return 0
  }
}

export async function getVeTTSupplyAndTotalStaked() {
  try {
    const contractAddress = config.stakingContractAddress.toLowerCase() as `0x${string}`
    const contractCallContext: ContractCallContext[] = [
      {
        reference: "pos",
        contractAddress: contractAddress,
        abi: PosStakingAbi.abi,
        calls: [
          { reference: "totalSupply", methodName: "totalSupply", methodParameters: [] },
          { reference: "getTTPool", methodName: "getTTPool", methodParameters: [] }
        ]
      }
    ]

    const {
      results: {
        pos: { callsReturnContext }
      }
    }: ContractCallResults = await multicall.call(contractCallContext)
    const [veTtSupply, totalStake] = callsReturnContext

    return {
      veTtSupply: parseInt(utils.formatEther(veTtSupply.returnValues[0].hex)),
      totalStake: parseInt(utils.formatEther(totalStake.returnValues[0].hex))
    }
  } catch (error) {
    console.error("getVeTTSupplyAndTotalStaked failed.", error)
    return {
      veTtSupply: 0,
      totalStake: 0
    }
  }
}
