import React from "react"
import { styled } from "@mui/material/styles"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"

interface IAccordion {
  title: string
  content: string
}
export interface IAccordions {
  dataSet: IAccordion[]
  category: string
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  "&.MuiAccordion-root": {
    borderRadius: "14px",
    backgroundColor: theme.palette.mode === "dark" ? "#121319" : "#fff"
  },
  "&.MuiAccordion-root:before": {
    display: "none"
  }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontSize: "24px" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#121319" : "#fff",
  "&.MuiAccordionSummary-root": {
    height: "68px",
    padding: "0 16px",
    borderRadius: "14px"
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)"
  },
  "& .MuiAccordionSummary-content": {
    margin: "16px 0"
  },
  "& .MuiAccordionSummary-content p": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.mode === "dark" ? "#fff" : "#0844D2"
  },
  "@media (min-width: 720px)": {
    "&.MuiAccordionSummary-root": {
      padding: "0 24px"
    }
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px",
  paddingBottom: "16px",
  "& p": {
    color: theme.palette.mode === "dark" ? "#9499AF" : "#707488",
    fontSize: "14px",
    lineHeight: "20px"
  },
  "@media (min-width: 720px)": {
    padding: "0 24px",
    paddingBottom: "16px"
  }
}))

const Accordions = (props: IAccordions) => {
  const { dataSet, category } = props

  return (
    <div className="flex flex-col space-y-[16px]">
      {dataSet.map((item: IAccordion, index) => {
        const { title, content } = item
        return (
          <Accordion key={`${category}-panel${index}`}>
            <AccordionSummary aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
              <p>{title}</p>
            </AccordionSummary>
            <AccordionDetails>
              <p>{content}</p>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

export default Accordions
