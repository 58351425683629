import React, { useState, useEffect, useCallback } from "react"
import { useAppDispatch } from "../../store/hooks"
import {
  updateWalletAddress,
  updateWalletBalance,
  updateStakedVeTT,
  updateShowAddress,
  updateClaimAmount,
  updateEarnedReward
} from "../../store/posSlice"
import { useSelector } from "react-redux"
import { useWalletWrapper } from "../../context/Wallet"
import { selectPOS } from "../../store/store"
import config from "../../config"
// import ConnectButton from "../Button/ConnectButton"
import ConnectWalletsModal from "../ConnectWallets"
import { ConnectButton } from "@rainbow-me/rainbowkit"

interface IConnectWalletProps extends IConnectWalletOwnProps {}

interface IConnectWalletOwnProps {}

const ConnectWallet: React.FC<IConnectWalletProps> = () => {
  const [hasAddress, setHasAddress] = useState(false)
  const [key, setKey] = useState(Date.now)
  const dispatch = useAppDispatch()
  const pos = useSelector(selectPOS)
  const { isConnectWalletsModal, setisConnectWalletsModal, setConnection, isConnected } =
    useWalletWrapper()
  const { walletAddress, showAddress } = pos.userStatus

  const resetWallet = useCallback(() => {
    dispatch(updateWalletAddress(""))
    dispatch(updateWalletBalance(0))
    dispatch(updateStakedVeTT("0"))
    dispatch(updateClaimAmount(0))
    dispatch(updateEarnedReward(0))
  }, [dispatch])

  const autoConnectInTTWallet = useCallback(async () => {
    if ("hubId" in window) {
      const account: string[] = await window.ethereum.request({
        method: "eth_requestAccounts",
        params: []
      })

      if (account.length > 0) {
        dispatch(updateWalletAddress(account[0].toLocaleLowerCase()))
        setHasAddress(true)
        dispatch(updateShowAddress(true))
      }
    }
  }, [dispatch])

  const onConnectWallet = useCallback(async () => {
    if (isConnected) {
      await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [{ eth_accounts: {} }]
      })

      setHasAddress(false)
      dispatch(updateShowAddress(false))
      resetWallet()
    } else {
      if (!("hubId" in window)) {
        const account: string[] = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: []
        })
        autoSwitchNetwork()

        if (account.length > 0) {
          dispatch(updateWalletAddress(account[0]))
          setHasAddress(true)
          dispatch(updateShowAddress(true))
        }
      } else {
        autoConnectInTTWallet()
      }
    }
  }, [isConnected, dispatch, resetWallet, autoConnectInTTWallet])

  useEffect(() => {
    if (!isConnected) resetWallet()
  }, [isConnected, resetWallet])
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        if (walletAddress !== "") {
          document.location.reload()
        }
      })
      window.ethereum.on("connect", () => {
        onConnectWallet()
      })
      window.ethereum.on("disconnect", () => {
        setHasAddress(false)
        dispatch(updateShowAddress(false))
        resetWallet()
      })
    }
  }, [onConnectWallet, dispatch, resetWallet, walletAddress])

  const autoSwitchNetwork = () => {
    const network = Number(config.chainId) || 18
    const networkInfo: { [key: number]: any } = {
      108: {
        chainId: "0x6c",
        chainName: "Thundercore Mainnet",
        rpcUrls: ["https://mainnet-rpc.thundercore.com"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      },
      18: {
        chainId: "0x12",
        chainName: "Thundercore Testnet",
        rpcUrls: ["https://testnet-rpc.thundercore.com"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      },
      19: {
        chainId: "0x13",
        chainName: "Thundercore Testnet QA02",
        rpcUrls: ["https://qa02-rpc.dev.tt-eng.com/"],
        iconUrls: ["https://thundercore.github.io/dist/thundercore.png"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"],
        nativeCurrency: {
          name: "Thundercore Token",
          symbol: "TT",
          decimals: 18
        }
      }
    }
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [networkInfo[network]] // you must have access to the specified account
        })
        .catch((error: any) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log("We can encrypt anything without the key.")
          } else {
            console.error(error)
          }
        })
    }
  }

  useEffect(() => {
    autoConnectInTTWallet()
  }, [autoConnectInTTWallet])

  useEffect(() => {
    setKey(Date.now())
  }, [pos.userStatus])

  return (
    <div className="flex items-center">
      <ConnectButton key={key} />
      <ConnectWalletsModal
        open={isConnectWalletsModal}
        closeModal={() => setisConnectWalletsModal(false)}
        connect={setConnection}
      />
    </div>
  )
}

export default ConnectWallet
