import * as React from "react"
import { styled, alpha } from "@mui/material/styles"
import Button from "@mui/material/Button"
import Menu, { MenuProps } from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { useTranslation } from "react-i18next"

const StyledButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#121319",
  border: "1px solid #424659",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#242633",
    borderColor: "transparent"
  },
  "&.open": {
    backgroundColor: "#242633",
    borderColor: "transparent"
  }
}))
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={10}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: 115,
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    minWidth: 180,
    color: "#fff",
    background: "#242633",
    "& .MuiMenu-list": {
      padding: "16px"
    },
    "& .MuiMenuItem-root": {
      minHeight: "auto",
      padding: "4px 12px",
      border: "1px solid transparent",
      "&:not(:last-child)": {
        marginBottom: "6px"
      },
      "&:hover": {
        backgroundColor: "#242633",
        borderColor: "#424659",
        color: "#09D1D1",
        borderRadius: 6
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const i18nOptions = [
  {
    short: "EN",
    lng: "English"
  },
  {
    short: "KO",
    lng: "한국어"
  }
]

export default function I18nMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { i18n } = useTranslation()
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    const index = i18nOptions.findIndex((item) => item.short === i18n.language.toUpperCase())
    return index !== -1 ? index : 0
  })
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index)
    setAnchorEl(null)
    i18n.changeLanguage(String(i18nOptions[index].short).toLowerCase())
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }

  return (
    <>
      <StyledButton
        id="i18n-menu-button"
        aria-controls={open ? "i18n-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        className={open ? "open" : ""}
      >
        {i18nOptions[selectedIndex].short}
      </StyledButton>
      <StyledMenu
        id="i18n-menu"
        MenuListProps={{
          "aria-labelledby": `i18n-menu-button`
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {i18nOptions.map((option, index) => (
          <MenuItem
            key={option.lng}
            // selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.lng}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  )
}
