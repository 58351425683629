import React from "react"
import Content from "../components/Body/Content"
import StakingForm from "../components/Body/StakingForm"
import FAQ from "./Body/FAQ"
import { useBreakpoints } from "../hooks/useBreakpoints"

interface IStakingBodyProps extends IStakingBodyOwnProps {}

interface IStakingBodyOwnProps {}

const StakingBody: React.FC<IStakingBodyProps> = () => {
  const { isTablet } = useBreakpoints()
  return (
    <div className="flex flex-col bg-neutrals-50 dark:bg-black">
      <Content />
      <div className="px-[24px]" style={{ marginTop: `${isTablet ? "-404px" : "-380px"}` }}>
        <StakingForm />
        <FAQ />
      </div>
    </div>
  )
}

export default StakingBody
