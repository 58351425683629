import React from "react"
import succIcon from "../../../../assets/successIcon.png"
import failedIcon from "../../../../assets/failedIcon.png"
import compute from "../../../../utils/compute"
import { useSelector } from "react-redux"
import { selectPOS } from "../../../../store/store"
import config from "../../../../config"
import { truncate } from "../../../../utils/truncateAddress"
import linkImg from "../../../../assets/link.svg"
import linkDarkImg from "../../../../assets/linkDark.svg"
import { ColorModeContext } from "../../../../context/Color"
import { useTranslation } from "react-i18next"

interface ITxResultProps extends ITxResultOwnProps {}

interface ITxResultOwnProps {
  claimAmount: number
  claimSuccess: boolean
  closeClaimModal: () => void
}

const TxResult: React.FC<ITxResultProps> = (props: ITxResultProps) => {
  const { t } = useTranslation()
  const { claimAmount, claimSuccess, closeClaimModal } = props
  const { isDarkMode } = React.useContext(ColorModeContext)
  const pos = useSelector(selectPOS)

  return (
    <div className="flex flex-col items-center">
      <img
        src={claimSuccess ? succIcon : failedIcon}
        className="w-[40px] h-[40px] mt-[24px]"
        alt={claimSuccess ? "success icon" : "failed icon"}
      />
      <div className="text-center mt-[12px] text-[16px] leading-[24px] text-black dark:text-white font-bold">
        {claimSuccess ? t("staking.stake.success") : t("staking.stake.failed")}
      </div>
      <div className="text-center mt-[4px] text-[14px] leading-[20px] text-secondary dark:text-neutrals-500">
        {claimSuccess ? t("staking.stake.successDesc") : t("staking.stake.failedDesc")}
      </div>
      <div className="w-full px-[18px] py-[16px] mt-[40px] bg-variant-50 dark:bg-neutrals-800 rounded-[10px]">
        <div className="flex flex-row justify-between">
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {claimSuccess ? t("staking.claim.claimed") : t("staking.claim.amount")}
          </div>
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {compute.roundNumberShort(claimAmount)} TT
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between w-full mt-[16px] px-[2px]">
        <div className="text-[14px] leading-[20px] dark:text-white text-black">
          {t("staking.stake.transactionID")}
        </div>
        <div className="flex flex-row">
          <div className="text-[14px] leading-[20px] dark:text-white text-black">
            {truncate(pos.userStatus.claimTxHash)}
          </div>
          <a href={`${config.explorerUrl}/tx/${pos.userStatus.claimTxHash}`} target="_blank">
            <img
              src={isDarkMode ? linkDarkImg : linkImg}
              className="w-[20px] h-[20px] ml-[4px]"
              alt={"Viewblock"}
            />
          </a>
        </div>
      </div>

      <div className="absolute w-full left-0 bottom-[16px] px-[16px]">
        <button onClick={() => closeClaimModal()} className="btn btn-secondary w-full h-[48px]">
          {t("staking.stake.ok")}
        </button>
      </div>
    </div>
  )
}

export default TxResult
