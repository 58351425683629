import React, { useState } from "react"
import StyledTooltip from "../Tooltip/Tooltip"
import { selectPOS } from "../../store/store"
import { useSelector } from "react-redux"
import compute from "../../utils/compute"
import { LinearProgress, linearProgressClasses } from "@mui/material"
import { styled } from "@mui/material/styles"
import config, { calcEpochInMs } from "../../config"
import infoIcon from "../../assets/Info.svg"
import { useTranslation } from "react-i18next"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === "dark" ? "#424659" : "#D6D8E4"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(107.56deg, #0844D2 0%, #09D1D1 100%);"
  }
}))

const StakingFormStats = () => {
  const { t } = useTranslation()
  const pos = useSelector(selectPOS)
  const [showApyTip, setShowApyTip] = useState(false)
  const [showEpochTip, setShowEpochTip] = useState(false)

  const { epochLeftTime, apy, stakingRatio, blockHeight } = pos.projectStatus

  const apyPercent = compute.roundApr(apy * 100)
  const epochInMs = calcEpochInMs(config)
  const percent =
    epochLeftTime - Date.now() > 0 ? (1 - (epochLeftTime - Date.now()) / epochInMs) * 100 : 0
  const timeFormat = compute.getRemainingTIme(epochLeftTime)
  return (
    <div
      className="`
      flex flex-col p-[16px] bg-white dark:bg-neutrals-900 rounded-[14px] mt-[16px] space-y-[8px]
      md:p-[24px] md:space-y-[12px]
    `"
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row ">
          <span className="dark:text-white text-[14px] leading-[20px] font-medium text-black">
            {t("staking.stats.apy")}
          </span>
          <StyledTooltip
            title={t("staking.stats.estimated")}
            open={showApyTip}
            leaveTouchDelay={10000}
            onOpen={() => setShowApyTip(true)}
            onClose={() => setShowApyTip(false)}
            placement="bottom-start"
          >
            <img
              className="w-[20px] h-[20px] ml-[4px]"
              onClick={() => setShowApyTip(!showApyTip)}
              src={infoIcon}
              alt={"info"}
            />
          </StyledTooltip>
        </div>
        <div className="text-[12px] leading-[20px] text-black dark:text-white">
          {apy * 100 > 10000 ? ">10000%" : `${apyPercent}%`}
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row ">
          <span className="dark:text-white text-[14px] leading-[20px] font-medium text-black">
            {t("staking.stats.epoch")}
          </span>
          <StyledTooltip
            title={t("staking.stats.desc")}
            open={showEpochTip}
            leaveTouchDelay={10000}
            onOpen={() => setShowEpochTip(true)}
            onClose={() => setShowEpochTip(false)}
            placement="bottom-start"
          >
            <img
              className="w-[20px] h-[20px] ml-[4px]"
              onClick={() => setShowEpochTip(!showEpochTip)}
              src={infoIcon}
              alt={"info"}
            />
          </StyledTooltip>
        </div>
        <div className="flex flex-col justify-end">
          <BorderLinearProgress variant="determinate" value={percent} className="w-[132px]" />
          <div className="text-secondary text-[12px] leading-[20px] text-right mt-[8px]">
            {t("staking.stats.nextEpoch", {
              date: `${timeFormat.displayHour}h ${timeFormat.displayMinutes}m`
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <span className="dark:text-white text-[14px] leading-[20px] font-medium text-black">
          {t("staking.stats.ratio")}
        </span>
        <div className="text-[12px] leading-[20px] text-black dark:text-white">{`${compute.roundNumber(
          stakingRatio * 100,
          1
        )}%`}</div>
      </div>
      <div className="flex flex-row justify-between">
        <span className="dark:text-white text-[14px] leading-[20px] font-medium text-black">
          {t("staking.stats.blockHeight")}
        </span>
        <div className="text-[12px] leading-[20px] text-black dark:text-white">
          {compute.addSeparator(blockHeight)}
        </div>
      </div>
    </div>
  )
}

export default StakingFormStats
