import React, { Fragment } from "react"
import { styled } from "@mui/material/styles"
import { Collapse, Divider, ListItemButton, ListItemText } from "@mui/material"
import List from "@mui/material/List"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import categoryList, { IFooterProps } from "./FooterConfig"

type IStyledListProp = {
  theme: any
}

const StyledList = styled((props: React.PropsWithChildren) => <List component="div" {...props} />)(
  ({ theme }: IStyledListProp) => ({
    "&.MuiList-root": {
      padding: "24px 0px"
    },
    "@media (min-width: 720px)": {
      "&.MuiList-root": {
        padding: "24px 16px"
      }
    }
  })
)

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: "#fff",
  padding: "20px 24px"
}))

const StyledCatListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontWeight: 500,
    fontFamily: "DM Sans"
  }
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: "DM Sans",
  color: "#9499AF",
  padding: "20px 32px",
  margin: 0,
  cursor: "pointer",
  "&:hover": {
    color: "#09D1D1"
  },
  "& .MuiTypography-root": {
    fontWeight: 500,
    fontFamily: "DM Sans"
  }
}))

const MobileCategory: React.FC<IFooterProps> = () => {
  const [openMap, setOpenMap] = React.useState(() => {
    return categoryList.reduce(
      (acc, current, index) => {
        acc[index] = false
        return acc
      },
      {} as Record<number, boolean>
    )
  })

  const handleCollapseClick = (index: number) => {
    setOpenMap((prev) => {
      return {
        ...prev,
        [index]: !prev[index]
      }
    })
  }

  const handleLinkClick = (link: string) => {
    window.open(link, "_blank")
  }
  return (
    <StyledList>
      {categoryList.map((category, cat_id) => (
        <div key={cat_id}>
          <StyledListItemButton
            key={`${cat_id}-item`}
            onClick={() => {
              handleCollapseClick(cat_id)
            }}
          >
            <StyledCatListItemText primary={category.title} />
            {openMap[cat_id] ? <ExpandLess /> : <ExpandMore />}
          </StyledListItemButton>
          <Collapse key={`${cat_id}-collapse`} in={openMap[cat_id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {category.linkList.map((x, i) => (
                <Fragment key={i}>
                  <StyledListItemText
                    primary={x.text}
                    onClick={() => {
                      handleLinkClick(x.src)
                    }}
                  />
                  {i !== category.linkList.length - 1 && (
                    <Divider sx={{ margin: "0 32px", borderColor: "#9499AF" }} />
                  )}
                </Fragment>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </StyledList>
  )
}

export default MobileCategory
