import React from "react"
import { styled } from "@mui/material/styles"
import Tabs from "@mui/material/Tabs"

interface StyledTabsProps {
  children?: React.ReactNode
  value: number
  onChange: (event: React.SyntheticEvent, newValue: number) => void
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs className="space-x-[8px] md:space-x-[16px]" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#121319" : "#fff",
  borderRadius: "64px",
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
    padding: "8px",
    position: "relative",
    zIndex: 100
  },
  "& .MuiTabs-indicator": {
    height: "42px",
    marginBottom: "8px",
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#0844D2",
    borderRadius: "48px"
  }
}))

export default StyledTabs
