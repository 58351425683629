import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import StakingStatsTooltip from "../Tooltip/StakingStatsTooltip/StakingStatsTooltip"

import { selectPOS } from "../../store/store"

import compute from "../../utils/compute"
import { ColorModeContext } from "../../context/Color"
import { useBreakpoints } from "../../hooks/useBreakpoints"

import bgStakeImg from "../../assets/bgStake.png"
import bgStakeDarkImg from "../../assets/bgStakeDark.png"

interface IContentProps extends IContentOwnProps {}

interface IContentOwnProps {}

const Content: React.FC<IContentProps> = () => {
  const [showStakingPowerTip, setStakingPowerShowTip] = React.useState(false)
  const { t } = useTranslation()
  const { isTablet } = useBreakpoints()
  const { isDarkMode } = React.useContext(ColorModeContext)
  const pos = useSelector(selectPOS).projectStatus
  const totalStakedTT = compute.addSeparator(pos.totalStakingScore)

  return (
    <div className="">
      <div
        className="w-full max-w-[1024px] md:max-w-[1280px] h-[720px] flex flex-col items-center mx-auto bg-no-repeat bg-top"
        style={{
          backgroundImage: `url(${isDarkMode ? bgStakeDarkImg : bgStakeImg})`,
          backgroundSize: `${isTablet ? "1024px 576px" : "1280px 720px"}`
        }}
      >
        <h1
          className="`
          mt-[56px] text-[34px] leading-[44px] font-bold text-white text-center font-mont
          lg:mt-[80px] lg:leading-[48px]
        `"
        >
          {t("staking.first.stakeTT")}
        </h1>
        <p
          className="`
          w-full max-w-[327px] mt-[12px] text-[14px] leading-[20px] text-center text-white
          md:mt-[8px]
        `"
        >
          {t("staking.first.desc")}
        </p>
        <div className="flex flex-col items-center space-y-[4px] mb-[40px] mt-[24px]">
          <div className="flex flex-row items-center">
            <div className="text-[14px] leading-[20px] text-secondary-500 font-medium">
              {t("staking.first.total")}
            </div>
            <StakingStatsTooltip
              showTip={showStakingPowerTip}
              setShowTip={setStakingPowerShowTip}
              tipContent={t("staking.first.stakingPowerTip")}
            />
          </div>
          <div className="text-[24px] leading-[32px] text-white font-bold font-mont">{`${totalStakedTT} TT`}</div>
        </div>
      </div>
    </div>
  )
}

export default Content
