import { ethers, utils } from "ethers"

import config, { stakingHelperCtorArg } from "../config"
import posStakingAbi from "../constant/abi/pos-staking-contract-abi.json"
import { getBlockHeight, getBlock } from "../utils/chainService"
import { StakingHelper } from "tt-staking-lib"
import { prepareWriteContract, writeContract, waitForTransaction, readContract } from "@wagmi/core"
import { useWalletWrapper } from "../context/Wallet"
import { useCallback, useMemo } from "react"
type Address = `0x${string}`

const UseChainService = () => {
  const { /*ethereum,*/ walletClient } = useWalletWrapper()

  const stakingHelper = useMemo(() => {
    return new StakingHelper(stakingHelperCtorArg(config))
  }, [])

  const provider = useMemo(() => {
    // NOTE: modify to use json rpc provider to get latest balance
    return new ethers.providers.JsonRpcProvider(config.rpcUrl)
    // return !!ethereum
    //   ? new ethers.providers.Web3Provider(ethereum)
    //   : new ethers.providers.JsonRpcProvider(config.rpcUrl)
  }, [])

  const readPosContract = useCallback(
    async (functionName: string, args?: any[], params: { [key: string]: any } = {}) => {
      const res = await readContract({
        address: config.stakingContractAddress as Address,
        abi: posStakingAbi.abi,
        functionName,
        args: args || [],
        ...params
      })
      return res as any
    },
    []
  )

  const callPosContract = useCallback(
    async (args: any, functionName: string, other?: { [key: string]: any }) => {
      const { request } = await prepareWriteContract({
        address: config.stakingContractAddress as Address,
        abi: posStakingAbi.abi,
        chainId: +config.chainId,
        functionName,
        args,
        ...other,
        walletClient
      })

      const detectTimeout = () =>
        new Promise((resolve, reject) => {
          writeContract(request)
            .then((x) => resolve(x.hash))
            .catch((e) => {
              reject(e)
            })
          setTimeout(
            () => {
              resolve(null)
            },
            5 * 60 * 1000
          )
        })

      const hash = await detectTimeout().catch((e) => {
        return null
      })
      if (!hash) return null
      return await waitForTransaction({ hash: hash as Address })
    },
    [walletClient]
  )

  const getUserBalance = async (address: string) => {
    return await provider.getBalance(address)
  }

  const stake = async (ether: string) => {
    try {
      const res = await callPosContract([], "enter", { value: utils.parseEther(ether) })
      return res
    } catch (err) {
      console.log("Stake failed.", err)
      return false
    }
  }

  const getUserStakedToken = async (address: string) => {
    try {
      return await readPosContract("balanceOf", [address]) // NOTICE: the value is in Wei, not VeTT
    } catch (err) {
      console.log("Get user staked token failed.", err)
      return false
    }
  }

  const unstake = async (veTT: string) => {
    try {
      const res = await callPosContract([utils.parseEther(veTT)], "leave")
      return res
    } catch (err) {
      console.log("Unstake failed.", err)
      return false
    }
  }

  const claim = async () => {
    try {
      const res = await callPosContract(null, "withdraw")
      return res
    } catch (err) {
      console.log("Claim failed.")
      return false
    }
  }

  const checkClaimability = async (address: string) => {
    try {
      const res = await readPosContract("canWithdraw", [address], { from: address })
      return res
    } catch (err) {
      console.log("check claimability failed.")
      return false
    }
  }

  const getUserClaimStatus = async (address: string) => {
    try {
      const res = await readPosContract("claim", [address], {
        from: address
      })

      return {
        amount: ethers.BigNumber.from(res[0]),
        session: ethers.BigNumber.from(res[1])
      }
    } catch (err) {
      console.log("get user claim status failed.")
      return {
        amount: ethers.BigNumber.from(0),
        session: ethers.BigNumber.from(0)
      }
    }
  }

  const getMINWaitingSession = async () => {
    try {
      const res = await readPosContract("minSession")
      return res
    } catch (err) {
      console.log("get min waiting session failed.")
      return false
    }
  }

  const getCurrentSession = async () => {
    try {
      const res = await readPosContract("getSession")
      return res
    } catch (err) {
      console.error("get current session failed.")
      return false
    }
  }

  const getUserWithdrawWaitingTime = async (address: string) => {
    const remainingEpochToClaim = await getRemainingEpochToClaim(address)
    const currentBlockHeight = await getBlockHeight()
    const currentBlockNonce = +(await getBlock(currentBlockHeight)).nonce
    const lastSessionLength = +(await getBlock(currentBlockHeight - currentBlockNonce)).nonce

    try {
      if (remainingEpochToClaim === 0) {
        return 0
      } else {
        return (
          (lastSessionLength * +remainingEpochToClaim - currentBlockNonce) *
          +config.avgMillisecondPerBlock
        )
      }
    } catch (err) {
      console.log("get user withdraw waitng time failed.")
      return -1
    }
  }

  const getRemainingEpochToClaim = async (address: string) => {
    try {
      const currentSession = ethers.BigNumber.from(await getCurrentSession())
      const MINWaitingSession = ethers.BigNumber.from(await getMINWaitingSession())
      const userClaimStatus = await getUserClaimStatus(address)
      if (currentSession.gt(userClaimStatus.session.add(MINWaitingSession))) {
        return 0
      } else {
        return Number(userClaimStatus.session.add(MINWaitingSession).sub(currentSession))
      }
    } catch (err) {
      console.log("Get remaining epoch to claim failed.", err)
      return -1
    }
  }

  const getEarnedRewards = async (walletAddress: string) => {
    try {
      const earnedWei = await stakingHelper.getWalletRewardsEarned(walletAddress)
      return parseFloat(ethers.utils.formatEther(earnedWei))
    } catch (error) {
      console.log("Get earned rewards failed.")
      return 0
    }
  }

  return {
    getUserStakedToken,
    getUserBalance,
    getUserClaimStatus,
    getEarnedRewards,
    stake,
    unstake,
    claim,
    checkClaimability,
    getRemainingEpochToClaim,
    getUserWithdrawWaitingTime
  }
}

export default UseChainService
