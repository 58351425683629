import React from "react"
import { styled } from "@mui/material/styles"
import Tab from "@mui/material/Tab"

interface StyledTabProps {
  label: string
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&.MuiTab-root": {
      color: theme.palette.mode === "dark" ? "#fff" : "black",
      borderRadius: "48px",
      flex: 1,
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "24px",
      paddingTop: "8px",
      paddingBottom: "8px",
      minHeight: "unset",
      backgroundColor: "transparent"
    },
    "&.Mui-selected": {
      color: theme.palette.mode === "dark" ? "#000000" : "#fff"
    }
  })
)

export default StyledTab
