import React, { Suspense } from "react"
import { Provider } from "react-redux"
import { RouterProvider, createHashRouter } from "react-router-dom"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"

import LandingContainer from "./containers/LandingContainer"
import StakingBody from "./components/StakingBody"

import { store } from "./store/store"

import Root from "./routes/root"
import themeConfig from "./theme"
import useDarkMode from "./hooks/useDarkmode"
import { ColorModeContext } from "./context/Color"
import { UseWalletWrapperProvider, WalletProvider } from "./context/Wallet/Context"
import "./i18n"

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <LandingContainer />
      },
      {
        path: "/staking",
        element: <StakingBody />
      }
    ]
  }
])

function App() {
  return (
    <UseWalletWrapperProvider>
      <WalletProvider>
        <Provider store={store}>
          <ThemedApp />
        </Provider>
      </WalletProvider>
    </UseWalletWrapperProvider>
  )
}

const ThemedApp = () => {
  const colorMode = useDarkMode()
  const theme = React.useMemo(() => {
    return createTheme({
      palette: {
        mode: colorMode.isDarkMode ? "dark" : "light"
      },
      ...themeConfig
    })
  }, [colorMode.isDarkMode])
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
