import React, { useState } from "react"
import waringIcon from "../../../../assets/waring.svg"
import StyledCheckbox from "../../../Checkbox/Checkbox"
import { useTranslation } from "react-i18next"

interface IRequestingProps extends IRequestingOwnProps {}

interface IRequestingOwnProps {
  handleDisclaimerConfirm: (notShowAgain: boolean) => void
}

const Requesting: React.FC<IRequestingProps> = (props: IRequestingProps) => {
  const { t } = useTranslation()
  const { handleDisclaimerConfirm } = props
  const [checkBox1, setCheckBox1] = useState(false)

  return (
    <div className="py-[24px] px-[16px] rounded-[14px] border-solid border-[1px] border-br-light dark:border-neutrals-700 dark:bg-neutrals-900">
      <div className="flex flex-col items-center">
        <img src={waringIcon} className="w-[32px] h-[32px] mb-[2px]" alt="warning" />
        <div className="dark:text-white text-[24px] leading-[32px] font-semibold">
          {t("staking.disclaimer.title")}
        </div>
      </div>
      <div className="p-[16px] mt-[24px] rounded-[12px] border-solid border-[1px] border-br-light dark:border-neutrals-700 bg-variant-50 dark:bg-neutrals-800">
        <div className="dark:text-white text-[16px] leading-[24px]">
          {t("staking.disclaimer.content")}
        </div>
      </div>
      <div className="flex flex-row items-center mt-[32px]">
        <StyledCheckbox checked={checkBox1} onChange={() => setCheckBox1(!checkBox1)} />
        <div className="flex-1 text-[14px] leading-[24px] font-mont dark:text-white">
          {t("staking.disclaimer.dontShow")}
        </div>
      </div>

      <div className="flex justify-between mt-[24px]">
        <button
          disabled={!checkBox1}
          onClick={() => handleDisclaimerConfirm(checkBox1)}
          className="btn btn-secondary w-full h-[48px]"
        >
          {t("staking.disclaimer.understand")}
        </button>
      </div>
    </div>
  )
}

export default Requesting
