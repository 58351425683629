const networkInfo = {
  18: {
    id: 18,
    network: "Thundercore",
    name: "Thundercore Testnet",
    nativeCurrency: { name: "Thundercore Token", symbol: "TTT", decimals: 18 },
    rpcUrls: {
      infura: {
        http: ["https://testnet-rpc.thundercore.com"],
        webSocket: ["wss://testnet-ws.thundercore.com"]
      },
      default: { http: ["https://testnet-rpc.thundercore.com"] },
      public: { http: ["https://testnet-rpc.thundercore.com"] }
    },
    contracts: {
      multicall3: {
        address: "0x9f5A50e297a43a26e04d05501e492D8a1cB08936" as `0x${string}`,
        blockCreated: 726960
      }
    },
    testnet: true
  },
  108: {
    id: 108,
    network: "Thundercore",
    name: "Thundercore Mainnet",
    nativeCurrency: { name: "Thundercore Token", symbol: "TT", decimals: 18 },
    rpcUrls: {
      infura: {
        http: ["https://mainnet-rpc.thundercore.com"],
        webSocket: ["wss://mainnet-ws.thundercore.com"]
      },
      default: { http: ["https://mainnet-rpc.thundercore.com"] },
      public: { http: ["https://mainnet-rpc.thundercore.com"] }
    },
    contracts: {
      multicall3: {
        address: "0xcA11bde05977b3631167028862bE2a173976CA11" as `0x${string}`,
        blockCreated: 148013830
      }
    }
  }
}
export const enum NETWORK_NAME {
  MAINNET = 108,
  TESTNET = 18
}

export default networkInfo
