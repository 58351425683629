import React from "react"
import loader from "../../../../assets/loader.gif"
import loaderDark from "../../../../assets/loaderDark.gif"
import compute from "../../../../utils/compute"
import { ColorModeContext } from "../../../../context/Color"
import { useTranslation } from "react-i18next"

interface IProcessingProps extends IProcessingOwnProps {}

interface IProcessingOwnProps {
  claimAmount: number
}

const Processing: React.FC<IProcessingProps> = (props: IProcessingProps) => {
  const { t } = useTranslation()
  const { isDarkMode } = React.useContext(ColorModeContext)
  const loaderImg = isDarkMode ? loaderDark : loader
  const { claimAmount } = props

  return (
    <div className="flex flex-col items-center">
      <img src={loaderImg} className="w-[64px] mt-[28px]" alt={"loading..."} />
      <div className="text-center mt-[16px] text-[16px] leading-[24px] text-black dark:text-white font-bold">
        {t("staking.claim.processing")}
      </div>
      <div className="text-center mt-[4px] text-[14px] leading-[20px] text-secondary dark:text-neutrals-500">
        {t("staking.unstake.wait")}
      </div>

      <div className="w-full px-[18px] py-[16px] mt-[40px] bg-variant-50 dark:bg-neutrals-800 rounded-[10px]">
        <div className="flex flex-row justify-between">
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {t("staking.stake.youWillGet")}
          </div>
          <div className="text-[12px] leading-[20px] dark:text-white text-black">
            {compute.roundNumberShort(claimAmount)} TT
          </div>
        </div>
      </div>
    </div>
  )
}

export default Processing
