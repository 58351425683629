import React from "react"
import { Checkbox, CheckboxProps } from "@mui/material"
import { styled } from "@mui/material/styles"
import checkedImg from "../../assets/checked.svg"
import uncheckedImg from "../../assets/unchecked.svg"
import checkedDarkImg from "../../assets/checkedDark.svg"
import uncheckedDarkImg from "../../assets/uncheckedDark.svg"
import { ColorModeContext } from "../../context/Color"

const StyledCheckbox = styled((props: CheckboxProps) => {
  const { isDarkMode } = React.useContext(ColorModeContext)
  return (
    <Checkbox
      checkedIcon={<img className="h-[20px]" src={isDarkMode ? checkedDarkImg : checkedImg} />}
      icon={<img className="h-[20px]" src={isDarkMode ? uncheckedDarkImg : uncheckedImg} />}
      disableRipple
      {...props}
    />
  )
})(({ theme }) => ({
  "&.MuiCheckbox-root": {
    paddingLeft: 0
  },
  "&:hover": {
    backgroundColor: "transparent"
  }
}))

export default StyledCheckbox
