import React from "react"
import Accordions from "./Accordions"
import TabPanel, { a11yProps } from "../Tabs/TabPanel"
import Tab from "../Tabs/Tab"
import StyledTabs from "../Tabs/Tabs"
import { useTranslation } from "react-i18next"

interface IFAQProps extends IFAQOwnProps {}

interface IFAQOwnProps {}

const FAQ: React.FC<IFAQProps> = () => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const faqDataSets = [
    {
      category: "General",
      dataSet: [
        {
          title: t("faq.general.q1"),
          content: t("faq.general.a1")
        },
        {
          title: t("faq.general.q2"),
          content: t("faq.general.a2")
        }
      ]
    },
    {
      category: "Stake",
      dataSet: [
        {
          title: t("faq.stake.q1"),
          content: t("faq.stake.a1")
        },
        {
          title: t("faq.stake.q2"),
          content: t("faq.stake.a2")
        },
        {
          title: t("faq.stake.q3"),
          content: t("faq.stake.a3")
        },
        {
          title: t("faq.stake.q4"),
          content: t("faq.stake.a4")
        },
        {
          title: t("faq.stake.q5"),
          content: t("faq.stake.a5")
        }
      ]
    },
    {
      category: "Unstake",
      dataSet: [
        {
          title: t("faq.unstake.q1"),
          content: t("faq.unstake.a1")
        },
        {
          title: t("faq.unstake.q2"),
          content: t("faq.unstake.a2")
        }
      ]
    }
  ]

  return (
    <div className="w-full max-w-[476px] mx-auto my-[40px] md:mb-[80px] md:mt-[48px]">
      <div className="text-[28px] leading-[40px] text-center font-mont font-semibold">FAQs</div>
      <div className="mt-[24px]">
        <StyledTabs value={value} onChange={handleChange} aria-label="faq tabs">
          <Tab label={t("faq.title.general")} {...a11yProps(0)} />
          <Tab label={t("faq.title.stake")} {...a11yProps(1)} />
          <Tab label={t("faq.title.unstake")} {...a11yProps(2)} />
        </StyledTabs>
      </div>
      {faqDataSets.map((set, index) => {
        return (
          <TabPanel value={value} index={index} key={index}>
            <Accordions {...set} />
          </TabPanel>
        )
      })}
    </div>
  )
}

export default FAQ
