import React, { useCallback, useEffect } from "react"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Outlet, ScrollRestoration } from "react-router-dom"
import { useAppDispatch } from "../store/hooks"
import {
  getBlockNumber,
  // updateTotalStake,
  updateTotalStakingScore,
  updateApy,
  updateTTPriceUsd,
  updateTokenHolders,
  updateStakingRatio,
  updateEpochLeftTime,
  // updateVeTTSupply,
  updateVeTTSupplyAndTotalStaked
} from "../utils/update"
import { updateBlockHeight } from "../store/posSlice"

export default function Root() {
  const dispatch = useAppDispatch()
  const getStates = useCallback(async () => {
    // dispatch(updateTotalStake())
    dispatch(updateVeTTSupplyAndTotalStaked())
    dispatch(updateTotalStakingScore())
    dispatch(updateStakingRatio())
    dispatch(updateApy())
    dispatch(updateEpochLeftTime())
    dispatch(updateTTPriceUsd())
    // dispatch(updateVeTTSupply())
    dispatch(updateTokenHolders())
  }, [])

  useEffect(() => {
    getStates()
  }, [])

  useEffect(() => {
    const updateProject = setInterval(async () => {
      getStates()
    }, 60000)

    const updateBlockNumber = setInterval(async () => {
      const block = await getBlockNumber()
      dispatch(updateBlockHeight(block))
    }, 1000)

    return () => {
      clearInterval(updateProject)
      clearInterval(updateBlockNumber)
    }
  }, [dispatch])

  return (
    <>
      <ScrollRestoration />
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
