import React, { Fragment, ReactNode } from "react"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import StakingStatsTooltip from "../Tooltip/StakingStatsTooltip/StakingStatsTooltip"

import { selectPOS } from "../../store/store"

import compute from "../../utils/compute"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { ColorModeContext } from "../../context/Color"

import Loading from "../../assets/apyLoading.gif"
import LoadingDark from "../../assets/apyLoadingDark.gif"

interface IStakingStatsProps extends IStakingStatsOwnProps {}

interface IStakingStatsOwnProps {}

interface IStakingStatsOptions {
  title: string | ReactNode
  value: string
  subTitle: string
}

const StakingStats: React.FC<IStakingStatsProps> = () => {
  const { isTablet } = useBreakpoints()

  return isTablet ? <MobileStats /> : <DesktopStats />
}

const DesktopStats: React.FC<IStakingStatsProps> = () => {
  const [showStakingPowerTip, setStakingPowerShowTip] = React.useState(false)
  const { isDarkMode } = React.useContext(ColorModeContext)
  const pos = useSelector(selectPOS).projectStatus
  const totalStakedTT = compute.addSeparator(pos.totalStakingScore)
  const totalHolders = compute.addSeparator(Number(pos.tokenHolders.toFixed(0)))
  const apyPercent = compute.roundApr(pos.apy * 100)
  const { t } = useTranslation()
  const stakingStatsOptions: IStakingStatsOptions[] = [
    {
      title: (
        <div className="flex flex-row items-center">
          <div>{t("landing.first.totalStaked")}</div>
          <StakingStatsTooltip
            showTip={showStakingPowerTip}
            setShowTip={setStakingPowerShowTip}
            tipContent={t("landing.first.stakingPowerTip")}
          />
        </div>
      ),
      value: `${totalStakedTT} TT`,
      subTitle: ""
    },
    {
      title: t("landing.first.historyApy"),
      value: pos.apy * 100 > 10000 ? ">10000%" : `${apyPercent}%`,
      subTitle: t("landing.first.estimated")
    },
    {
      title: t("landing.first.totalStakers"),
      value: totalHolders,
      subTitle: ""
    }
  ]

  return (
    <div
      className="`
      flex flex-row justify-between mt-[56px] bg-white w-[793px] p-[24px] border-solid border-[2px] border-br-light rounded-[18px]
      dark:bg-neutrals-900 dark:border-neutrals-700
    `"
    >
      {stakingStatsOptions.map((option, index) => {
        return (
          <div
            className={clsx(
              "flex-1",
              index !== 0 &&
                "pl-[24px] border-l-[1px] border-solid border-br-light dark:border-neutrals-700"
            )}
            key={index}
          >
            <div className="text-[16px] leading-[24px] font-bold text-primary-700 dark:text-secondary-500">
              {option.title}
            </div>
            {option.value === "0%" ? (
              <img src={isDarkMode ? LoadingDark : Loading} alt="Loading..." width="32" />
            ) : (
              <div className="mt-[4px] text-[24px] leading-[32px] font-bold font-mont text-black dark:text-white">
                {option.value}
              </div>
            )}
            {option.subTitle && (
              <div className="text-[14px] leading-[20px] text-secondary">{option.subTitle}</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const MobileStats: React.FC<IStakingStatsProps> = () => {
  const [showApyTip, setShowApyTip] = React.useState(false)
  const [showStakingPowerTip, setStakingPowerShowTip] = React.useState(false)
  const { isDarkMode } = React.useContext(ColorModeContext)
  const pos = useSelector(selectPOS).projectStatus
  const totalStakedTT = compute.addSeparator(pos.totalStakingScore)
  const totalHolders = compute.addSeparator(Number(pos.tokenHolders.toFixed(0)))
  const apyPercent = compute.roundApr(pos.apy * 100)
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="flex flex-col items-center space-y-[4px] mb-[24px] mt-[16px]">
        <div className="flex flex-row items-center">
          <div className="text-[14px] leading-[20px] text-secondary-500 font-medium">
            {t("landing.first.totalStaked")}
          </div>
          <StakingStatsTooltip
            showTip={showStakingPowerTip}
            setShowTip={setStakingPowerShowTip}
            tipContent={t("landing.first.stakingPowerTip")}
          />
        </div>
        <div className="text-[24px] leading-[32px] text-white font-bold font-mont">{`${totalStakedTT} TT`}</div>
      </div>
      <div
        className="`
        flex flex-row justify-between bg-white w-[327px] py-[18px] px-[24px] border-solid border-[2px] border-br-light rounded-[18px]
        dark:bg-neutrals-900 dark:border-neutrals-700
      `"
      >
        <div className="flex-1">
          <div className="flex flex-row items-center">
            <span className="text-[14px] leading-[20px] font-medium text-secondary">
              {t("landing.first.historyApy")}
            </span>
            <StakingStatsTooltip
              showTip={showApyTip}
              setShowTip={setShowApyTip}
              tipContent={t("landing.first.estimated")}
            />
          </div>
          {pos.apy ? (
            <div className="mt-[4px] text-[20px] leading-[28px] font-bold font-mont text-black dark:text-white">
              {pos.apy * 100 > 10000 ? ">10000%" : `${apyPercent}%`}
            </div>
          ) : (
            <img src={isDarkMode ? LoadingDark : Loading} alt="Loading..." width="28" />
          )}
        </div>
        <div className="flex-1 pl-[24px] border-l-[1px] border-solid dark:border-neutrals-700 border-br-light">
          <div className="text-[14px] leading-[20px] text-secondary font-medium">
            {t("landing.first.totalStakers")}
          </div>
          <div className="mt-[4px] text-[20px] leading-[28px] font-bold font-mont text-black dark:text-white">
            {totalHolders}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default StakingStats
