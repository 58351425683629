// A custom theme for this app
const themeConfig = {
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          [`&.Mui-checked`]: {
            color: "#595959"
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: "red",
          boxShadow: "0px 0px 4px rgba(222, 226, 245, 0.5)",
          borderRadius: 8,
          width: 200,
          padding: 16
        },
        arrow: {
          width: 17,
          "&::before": {
            backgroundColor: "white",
            boxShadow: "0px 0px 4px rgba(222, 226, 245, 0.5)"
          }
        }
      }
    }
  }
}

export default themeConfig
