import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ComponentProps,
  ReactNode,
  ReactElement
} from "react"

import JwtDecode from "jwt-decode"
import { ethers } from "ethers"
import LogRocket from "logrocket"
import config from "../../config"
import networkInfo from "../../constant/networkInfo"

import { configureChains, createConfig, WagmiConfig, mainnet, useWalletClient } from "wagmi"
import {
  RainbowKitProvider,
  connectorsForWallets,
  Chain,
  useConnectModal
} from "@rainbow-me/rainbowkit"
import { jsonRpcProvider } from "wagmi/providers/jsonRpc"
import { walletConnectWallet, metaMaskWallet } from "@rainbow-me/rainbowkit/wallets"
import { useAccount, useConnect, useBalance, useNetwork } from "wagmi"
import "@rainbow-me/rainbowkit/styles.css"
import { InjectedConnector, switchNetwork } from "@wagmi/core"
import { w3mProvider } from "@web3modal/ethereum"

import { updatePOSContract } from "../../utils/chainService"

interface IWalletProviderProps extends IWalletProviderOwnProps {}
type IWalletProviderOwnProps = {
  children: ReactNode
}
interface IWalletProviderProps extends ComponentProps<any> {}
interface CommonProps {
  children: ReactNode
}

const projectId = "abe6d2079ebc3910ccf5439c2ede043c"
const chainId = Number(config.chainId || 108)

const targetChain: Chain = networkInfo[chainId]
const isHub = !!window.ESportsIdToken

export const walletContext = createContext(null)

export function getHub() {
  try {
    return JwtDecode<any>(window.ESportsIdToken || "")
  } catch (error) {
    console.log("error:", error)
  }
}

export const UseWalletWrapperProvider = ({ children }: React.PropsWithChildren): ReactElement => {
  const { publicClient, chains } = configureChains(
    [networkInfo[108], networkInfo[18]],
    [
      w3mProvider({
        projectId
      }),
      jsonRpcProvider({
        rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] })
      })
    ]
  )

  const connectors = connectorsForWallets([
    {
      groupName: "Wallets",
      wallets: [walletConnectWallet({ projectId, chains }), metaMaskWallet({ projectId, chains })]
    }
  ])
  const ttWalletConnector = new InjectedConnector({
    chains: [targetChain],
    options: {
      name: "TT Wallet",
      getProvider: () => (typeof window !== "undefined" ? window.ethereum : undefined)
    }
  })

  const wagmiConfig = createConfig({
    // autoConnect: isHub,
    autoConnect: true,
    connectors: isHub ? [ttWalletConnector] : connectors,
    publicClient
  })
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={[targetChain]}>
        <div className="App">{children}</div>
      </RainbowKitProvider>
    </WagmiConfig>
  )
}

export function useWalletWrapper(): any {
  const wallet = useContext<any>(walletContext)
  return wallet
}

export const WalletProvider = ({ children }: React.PropsWithChildren) => {
  const { address, isConnected, connector } = useAccount()

  const { data: balance } = useBalance({
    address: address
  })
  const { connect, connectors, error, pendingConnector } = useConnect()
  const { chain, chains } = useNetwork()
  const { openConnectModal } = useConnectModal()
  const { data: walletClient } = useWalletClient()

  const [isNoWalletModal, setisNoWalletModal] = useState(false)
  // const [isConnectWalletsModal, setisConnectWalletsModal] = useState(false)

  const [currentConnectionType, setcurrentConnectionType] = useState("")
  const [customProvider, setcustomProvider] = useState<any>(null)
  const [walletVal, setWalletVal] = useState({
    account: address,
    balance: balance?.formatted,
    chainId: chainId,
    ethereum: isConnected && connector ? (connector.getWalletClient() as any) : "",
    status: "",
    walletClient: null
  })

  const isConnectWalletsModal = false
  const setisConnectWalletsModal = (props: boolean) => {
    if (openConnectModal) openConnectModal()
  }

  const connectByNetwork = () => {
    setcustomProvider(new ethers.providers.JsonRpcProvider(config.rpcUrl))
  }

  const setConnection = async (type: string) => {
    try {
      // deprecated
    } catch (error) {
      console.log("connection error:", error)
    }
  }

  // initialize
  useEffect(() => {
    async function checkNetworkAccountChange() {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", () => document.location.reload())
        window.ethereum.on("chainChanged", () => document.location.reload())
      }
    }
    const connectOnInitialize = async () => {
      if (isHub) {
        const connector = connectors[0]
        connector.connect()
      }
    }
    connectOnInitialize()
    checkNetworkAccountChange()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchFn = async () => {
      if (!isConnected && walletVal.account) {
        return setWalletVal({
          account: undefined,
          balance: undefined,
          chainId: chainId,
          ethereum: null,
          status: "",
          walletClient: null
        })
      }

      let newWallet = {
        ...walletVal,
        account: address,
        balance: balance?.formatted,
        ethereum: isConnected && connector ? await connector.getProvider() : "",
        walletClient: isConnected && connector ? await connector.getWalletClient() : null
      }

      if (isHub && connector) {
        const hubData = isHub ? getHub() : null

        const provider = await connector.getProvider()
        const account = await connector.getAccount()
        const client = await connector.getWalletClient()

        newWallet = {
          ...walletVal,
          account: account,
          chainId: Number(config.chainId),
          ethereum: provider,
          status: "connected",
          walletClient: client
        }
      }

      if (customProvider && !walletVal.ethereum) {
        newWallet.ethereum = customProvider
      }
      setWalletVal(newWallet)
    }
    fetchFn()
  }, [isHub, customProvider, address, connector, isConnected, walletClient])

  useEffect(() => {
    if (walletVal.ethereum) updatePOSContract(walletVal.ethereum)
  }, [walletVal.ethereum, walletVal.walletClient])

  useEffect(() => {
    if (walletVal.account) setLogRocketIdentifier(walletVal.account)
  }, [walletVal.account])

  const values = {
    isConnected,
    ...walletVal,
    isNoWalletModal,
    connectByNetwork,
    setConnection,
    currentConnectionType,
    setisNoWalletModal,
    isConnectWalletsModal,
    setisConnectWalletsModal
  }

  return <walletContext.Provider value={values as any}>{children}</walletContext.Provider>
}

export const setLogRocketIdentifier = (account: string) => {
  LogRocket.identify(account)
}
