import React from "react"
//import { makeStyles } from "@mui/styles"
import Modal from "@mui/material/Modal"

interface IModalComponentProps extends IModalComponentOwnProps, React.PropsWithChildren {}

interface IModalComponentOwnProps {
  open: boolean
  modalContentStyle: string
}

const ModalComponent: React.FC<IModalComponentProps> = ({ children, open, modalContentStyle }) => {
  return (
    <Modal open={open} onClose={() => {}} disableAutoFocus={true}>
      <div className={modalContentStyle}>{children}</div>
    </Modal>
  )
}

export default ModalComponent
