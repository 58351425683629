import React from "react"
import categoryList, { IFooterProps, ITextLink } from "./FooterConfig"

const TextLink = (props: ITextLink) => {
  const { text, src } = props
  return (
    <a
      className="block text-neutrals-500 hover:text-secondary-500"
      href={src}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
}

const DesktopCategory: React.FC<IFooterProps> = () => {
  return (
    <div className="flex flex-row justify-between p-[80px] max-w-[1440px] mx-auto">
      {categoryList.map((category, cat_id) => (
        <div className="space-y-[16px]" key={cat_id}>
          <div className="text-white font-medium">{category.title}</div>
          {category.linkList.map((x, i) => (
            <TextLink key={`link_${i}`} {...x} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default DesktopCategory
