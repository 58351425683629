import React from "react"
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.mode === "dark" ? "#424659" : "#3B79F6",
    color: "white",
    boxShadow: "4px 8px 12px rgba(0, 0, 0, 0.12)",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "20px",
    padding: "16px",
    marginTop: "8px !important"
  }
}))

export default StyledTooltip
